import React, { useRef, useState } from 'react';
import UploadWrapper from './upload_wrapper';

const Uploader = ({ editable, label, accept, name, errors={}, defaultValue={}, fileInputName, _ref_, onDelete, onChange, wrapper, defaultColor, ...props }) => {	
	const input = useRef();
	errors = errors || {};
	const error = props.error || errors[name];
	const [colors, setColors] = useState([]);
	const [color, setColor] = useState(defaultColor);
	
	return(
		<UploadWrapper name={name} editable={editable} defaultValue={defaultValue} onChange={onChange} onDelete={onDelete} className="overflow-visible">
			{({	
				loading, progress, completed,
				thumbnail, videoThumbnail, genericThumbnail,
				fileName, onFileSelected, onDrop, blobId, handleDelete,
			}) => (
				<div className={wrapper || ""} >
					{
						error &&
						<div className="input">
							<div className="error">
								{error}
							</div>
						</div>
					}
					<div>
						<div
							className="flex-col text-center bg-move-white border-dotted border-2 border-move-slate rounded-md p-12"
							onDragOver={e => e.preventDefault()}
							onDragEnter={e => {
								e.preventDefault();
								e.target.classList.add("border-move-orange");
								e.target.classList.remove("border-move-lgrey");
							}}
							onDragLeave={e => {
								e.preventDefault();
								e.target.classList.remove("border-move-orange");
								e.target.classList.add("border-move-lgrey");

							}}
							onDrop={onDrop}
						>
							<div className="py-2 text-center text-lg font-base">
								<span>Drop a file here or <span onClick={() => input.current.click()} className="text-move-blue cursor-pointer">browse</span>.</span>
							</div>
							
						</div>
						{blobId && <input name={name} type="hidden" value={blobId} ref={_ref_} />}
						<input type="file" name={fileInputName} className="hidden" ref={input} onChange={onFileSelected} accept={accept || '*/*'} />
					</div>
					<div className="flex justify-between mx-3 items-center">
						<div className="flex items-center py-2">

							<div className="flex items-center pointer-events-none">
								{
									loading &&

									<div>
										<div>{progress}</div>
										<div>
											<div className="lds-ring">
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										</div>
									</div>
								}
								{
									completed &&

									<div className='flex justify-start gap-2 items-center'>
										{
									
											thumbnail

											&&	<div className="w-full text-center py-2 pointer-events-none">
													<img style={{height: '45px'}} className="inline rounded-sm" src={thumbnail} />
												</div>
										}
										{
											
											videoThumbnail

											&&	<div className="w-full text-center py-2 pointer-events-none">
													<i style={{fontSize: '45px'}} className="fa-solid fa-file-video text-move-orange" />
												</div>
										}
										{
											
											genericThumbnail

											&&	<div className="w-full text-center py-2 pointer-events-none">
													<i style={{fontSize: '45px'}} className="fa-solid fa-file-alt text-move-orange" />
												</div>
										}
										<div className="text-sm font-base text-move-slate pointer-events-none">
											{fileName}
										</div>
										<i className="fa-regular fa-circle-check text-move-green text-md"></i>
									</div>
								}
							</div>
						</div>
						{
							!!fileName	&&

							<div className="flex font-base text-move-red cursor-pointer" onClick={handleDelete} >
								<i className="fas fa-trash-alt mr-2"/>
							</div>
						}
					</div>
				</div>
			)}
		</UploadWrapper>
	);
};

export default Uploader;
