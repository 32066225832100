import React from 'react';

const _404_ = () => (
    <div className='font-move flex justify-center items-center h-screen'>
        <div className='flex-col text-center gap-3 rounded py-4 px-6 bg-move-lblue text-move-dblue'>
            <div className='text-4xl font-bold'>404</div>
            <div className='text-xl font-base'>Uh-oh! You found a page that does not exist.</div>
        </div>
    </div>
);

export default _404_;