import React from 'react';
import { Avatar } from '../components';
import { formatPhoneNumber } from '../utils';

const AdvisorCard = ({ advisor, copy }) => {
    if (!advisor) return null;
    return (
        <div className='flex justify-between items-start shadow-sm rounded-lg bg-move-offwhite p-3'>
            <div className='mr-4'>
                <div className="text-xs font-semibold text-move-slate mb-1">{copy}</div>
                <div className="font-bold text-md">{advisor.name}</div>
                <div className='text-sm mb-2'>{advisor.account_name}</div>
                <div className='text-sm flex items-center gap-2'>
                    <i className='text-move-blue fa-regular fa-envelope' />
                    <a className='no-underline' href={`mailto:${advisor.email}`} >{advisor.email}</a>
                </div>
                <div className='text-sm flex items-center gap-2'>
                    <i className='text-move-blue fa-solid fa-phone' />
                    <a className='no-underline' href={`tel:${advisor.phone_number}`}>{formatPhoneNumber(advisor.phone_number)}</a>
                </div>
            </div>
            <Avatar
                className='rounded-full shadow-md'
                size={120}
                user={advisor}
            />
        </div>
    );
};

export default AdvisorCard;
