import React from 'react';
import { withStuff } from "../hocs";
import { toUSD } from '../utils';

const FinancialImpact = ({ state }) => {
    const policy = state.policies.find(policy => policy.major_medical);
    if (!policy) return null;

    return(
        <div className='mt-4 px-2'>
            <div className='font-bold mb-2'>HEALTH INSURANCE PLAN IMPACT</div>
            <div className='flex items-end justify-between mt-2'>
                <div>Premium</div>
                <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                <div>{toUSD(policy.premium)}</div>
            </div>
            <div className='flex items-end justify-between mt-2'>
                <div>Deductible</div>
                <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                <div>{toUSD(policy.deductible)} | {toUSD(policy.deductible * 2)}</div>
            </div>
            <div className='flex items-end justify-between mt-2'>
                <div>Max Out-of-Pocket</div>
                <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                <div>{toUSD(policy.moop)} | {toUSD(policy.moop * 2)}</div>
            </div>
            <div className="border-b border-black my-3"></div>
            <div className='flex items-end justify-between mt-2'>
                <div>Fixed Annual Cost</div>
                <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                <div>{toUSD(policy.premium * 12)}</div>
            </div>
            <div className='flex items-end justify-between mt-2'>
                <div>Additional Annual Exposure</div>
                <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                <div>{toUSD(policy.moop * 2)}</div>
            </div>
        </div>
    );
}

export default withStuff(FinancialImpact, {
    state: true,
    api: true,
    dependency: "client",
    loader: "policies",
    effect: ({ api, state }) => {
        api.getPolicies(state.client.id);
    }
});
