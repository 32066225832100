import React from 'react';

const ClientStatus = ({ status }) => {

    let color;
    switch (status) {
        case 'referred':
            color = 'blue';
            break;
        case 'scheduled':
            color = 'yellow';
            break;
        case 'pending':
            color = 'purple';
            break;
        case 'enrolled':
            color = 'green';
            break;
        case 'cancelled':
            color = 'red';
            break;
        default:
            color = 'gray';
            break;
    }
    return(
        <div className={`text-move-d${color} rounded-md bg-move-l${color} px-2 py-.75`}>
            <span className="text-xs font-semibold uppercase">
                {status}
            </span>
        </div>
    );

};

export default ClientStatus;
