import React from 'react';
import { capitalize } from '../utils';

const Select = ({ _ref_, id, style, allowBlank=false, onChange, options, errors={}, defaultValue, name, placeholder, label, required, className='', capitalizeOptions, readOnly }) => {
	errors = errors || {};
	const error = errors[name];

	function buildOptions() {
	    if (Array.isArray(options)) {
	        return options.map((option, i) => {
	        		if (Array.isArray(option)) {
			            return(
			            	<option key={i} value={option[1]} selected={defaultValue == option[1]}>
				                {capitalizeOptions ? capitalize(option[0]) : option[0]}
				            </option>
				        );
	        		} else {
			            return(
			            	<option key={i} value={option} selected={defaultValue == option}>
				                {capitalizeOptions ? capitalize(option) : option}
				            </option>
				        );
				    }
		        }
	        )
	    } else {
	        return Object.keys(options).map((key, i) =>
	            <option key={i} value={key} selected={defaultValue == key}>
	                {options[key]}
	            </option>
	        )
	    };
	}

	return(
		<div className={`input ${className} relative cursor-pointer hover:text-move-orange`} >
			{label && <label htmlFor={name} >
				{
					required && 
					<abbr title="required">* </abbr>
				}
				{label}
			</label>}
			<select
				id={id}
				className={placeholder ? 'has-placeholder' : ''}
				style={style}
			    ref={_ref_} 
			    onChange={onChange}
			    name={name}
			    readOnly={readOnly}
			    disabled={readOnly}
			    required={required}
			>
					<option disabled selected={true} >{placeholder}</option>
					{allowBlank && <option></option>}
			    {buildOptions()}
			</select>
			<i className="fas fa-caret-down absolute hover:text-move-orange" style={{right: "10px", top: "10px"}} />
			{
				error &&

				<div className="error">
					{error}
				</div>
			}
		</div>
	);
};

export default Select;