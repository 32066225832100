import React from 'react';
import { Form, Breadcrumb } from '../components';
import { withStuff } from '../hocs';

const NewClient = ({ state }) => {
    
    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
            <Breadcrumb self='Refer Client' />
            <div className='flex justify-center mt-12'>
                <div className='max-w-md'>
                    <div className="text-center mb-2 text-2xl font-semibold text-move-teal">Refer a new client</div>
                    <p className='text-center mb-4 text-move-navy font-light'>Provide some basic info & we will do the rest</p>
                    <Form
                        onSubmit="createClient"
                        type="create_client"
                        col="12"
                        submitCopy="Refer Client"
                        inputs={[
                            {
                                label: 'First name',
                                name: 'first_name',
                                required: true,
                            },
                            {
                                label: 'Last name',
                                name: 'last_name',
                                required: true,
                            },
                            {
                                label: 'Email',
                                required: true,
                                type: 'email',
                                name: 'email',
                            },
                            {
                                label: 'Phone',
                                type: 'tel',
                                name: 'phone_number',
                                placeholder: '(XXX) XXX-XXXX',
                            },
                            {
                                label: 'What kind of coverage is needed?',
                                type: 'select',
                                name: 'client_request_type',
                                options: {
                                    pre_65: 'Pre-65',
                                    medicare: 'Medicare',
                                    combo: 'Pre-65 & Medicare'
                                },
                                placeholder: 'Choose from the dropdown',
                            },
                            {
                                label: 'When is coverage needed?',
                                type: 'date',
                                name: 'projected_start_date',
                            },
                            (
                                state.user?.super_admin

                                ? {
                                    label: 'Who is the Financial Advisor?',
                                    type: 'select',
                                    name: 'financial_advisor_id',
                                    placeholder: 'Choose from the dropdown',
                                    options: (state.advisor_users || []).map(user => [user.name, user.id]),
                                }

                                : null
                            ),
                            {
                                label: 'Any notes you would like to share?',
                                type: 'textarea',
                                name: 'advisor_notes',
                                placeholder: 'Notes shared will remain confidential between you and Move Health and will not be shared with your client.',
                                rows: 5,
                            },
                            {
                                label: 'Do not send referral email',
                                type: 'check',
                                name: 'do_not_send_referral_email',
                                defaultValue: false,
                            }
                        ].filter(Boolean)}
                    />
                </div>
            </div>
        </div>
    )
};

export default withStuff(NewClient, {
    api: true,
    state: true,
    dependency: 'user',
    effect: ({ api, state }) => {
        if (state.user?.super_admin) {
            api.getAdminUsers({ type: 'AdvisorUser', set: 'advisor_users' })
        }
    }
});
