import React from 'react';
import Spinner from '../assets/spinner.svg';

const Loader = () => (
    <div style={{ textAlign: 'center' }} className="spinner flex justify-center items-center h-screen" >
        <div>
            <img src={Spinner} alt="loading" className='m-auto'/>
        </div>
    </div>
);

export default Loader;