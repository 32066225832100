import React from 'react';

const Breadcrumb = ({ parent, parentHref, self }) => (
    <div className='text-move-navy uppercase text-sm font-normal my-4'>
        {
            parent &&
            <>
                <a href={parentHref} className='no-underline'>
                    <i className="fa-solid fa-angles-left mr-1" />
                    {parent}
                </a>
                <span className="mx-2">/</span>
            </>
        }
        <span>{self}</span>
    </div>
);

export default Breadcrumb;
