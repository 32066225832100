import React, { useState, useEffect, useRef } from 'react';
import { Form, Uploader, Breadcrumb } from '../components';
import { userParams } from '../params';
import { withStuff } from '../hocs';


const Profile = ({ state, api }) => {

    function updateUser(params) {
        api.updateUser(state.user.id, params);
    }

    const user = state.other_user || state.user;

    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
			<Breadcrumb self='Profile' />
			<div className='xl:flex gap-3 mt-12 items-start'>
				<div className='w-full lg:w-2/3'>
					<div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4 mb-4'>
                        <div className='flex justify-between items-start'>
							<span className='text-lg font-medium'>Profile Info</span>
                        </div>
                        <Form
                            col="6"
                            onSubmit={updateUser}
                            submitCopy="Save Changes"
                            type="update_user"
                            inputs={userParams(user)}
                        />
                    </div>
                    <div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4 mb-4'>
                        <div className='flex justify-between items-start'>
							<span className='text-lg font-medium'>Profile Photo</span>
                        </div>
                        <Uploader
                            defaultValue={user.avatar}
                            name={`${user.first_name}_${user.last_name}_avatar`}
                            edit={true}
                            type="avatar"
                            editable={true}
                            onChange={({ target }) => {
                                api.updateUser(user.id, { avatar: target.value })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withStuff(Profile, {
    api: true,
    state: true,
    loader: 'user',
    effect: ({ match }) => {
        if (match.params.id) {
            api.adminGetUser(match.params.id);
        }
    }
});