import React, { useState } from 'react';
import Logo from '../assets/logo.svg';
import { Avatar, OutsideAlerter } from '../components';
import { usePageWidth } from '../hooks';
import { MoveSvg } from '../utils';
import { withStuff } from "../hocs";
import { target } from 'tailwindcss/stubs/defaultConfig.stub';
import { Tooltip } from 'react-tippy';

const NavBar = ({ location, api, state }) => {
    const [showHam, setShowHam] = useState(false);
    const pageWidth = usePageWidth();
    const isMobile = pageWidth < 1024;
    const clientShow = !state.user.type || state.user.type === 'ClientUser';

    const items = [
        {
            icon: 'fa-solid fa-gauge',
            name: 'Dashboard',
            href: '/',
            current: location.pathname === '/'
        },
        {
            icon: 'fa-solid fa-user',
            name: 'Clients',
            href: '/clients',
            current: location.pathname === '/clients'
        },
        {
            icon: 'fa-solid fa-calculator',
            name: 'Estimator',
            href: '/estimations',
            current: location.pathname === '/estimations'
        },
        {
            icon: 'fa-solid fa-compass',
            name: 'Resources',
            target: '_blank', 
            rel: 'noopener noreferrer',
            href: 'https://advisors.movehealth.io/advisorresources'
        }
    ];

    if (state.user.super_admin) {

        items.push({
            icon: 'fa-solid fa-city',
            name: 'Accounts',
            href: '/accounts',
            current: location.pathname === '/accounts'
        });
        
        items.push({
            icon: 'fa-solid fa-users',
            name: 'Users',
            href: '/users',
            current: location.pathname === '/users'
        });

        items.push({
            icon: 'fa-solid fa-upload',
            name: 'Importer',
            href: '/policy_import',
            current: location.pathname === '/policy_import'
        })
        
    }

    let profileItems;


    if (clientShow) {
        profileItems = [
            {
                icon: 'fa-solid fa-home',
                name: 'Home',
                href: '/',
                current: location.pathname === '/'
            },
            {
                icon: 'fa-solid fa-circle-user',
                name: 'Profile',
                href: '/profile',
                current: location.pathname === '/profile'
            },
            {
                icon: 'fa-solid fa-door-open',
                name: 'Sign Out',
                href: '#',
                onClick: api.signOut,
                current: location.pathname === '/sign_out'
            },
        ];
    } else {
        profileItems = [
            {
                icon: 'fa-solid fa-gauge',
                name: 'Dashboard',
                href: '/',
                current: location.pathname === '/',
                mobileOnly: true
            },
            {
                icon: 'fa-solid fa-user',
                name: 'Clients',
                href: '/clients',
                current: location.pathname === '/',
                mobileOnly: true
            },
            {
                icon: 'fa-solid fa-calculator',
                name: 'Estimator',
                href: '/estimations',
                current: location.pathname === '/estimations',
                mobileOnly: true
            },
            {
                icon: 'fa-solid fa-compass',
                name: 'Resources',
                target: '_blank', 
                rel: 'noopener noreferrer',
                mobileOnly: true,
                href: 'https://movehealthpartners.com/financial-advisor-resources'
            },
            ((state.user.admin || state.user.super_admin) && {
                icon: 'fa-solid fa-building',
                name: 'Account',
                href: '/account',
                current: location.pathname === '/account'
            }),
            {
                icon: 'fa-solid fa-circle-user',
                name: 'Profile',
                href: '/profile',
                current: location.pathname === '/profile'
            },
            {
                icon: 'fa-solid fa-door-open',
                name: 'Sign Out',
                href: '#',
                onClick: api.signOut,
                current: location.pathname === '/sign_out'
            },
        ].filter(Boolean);
    }

    return(
        <>
            {
                !clientShow && 

                <>
                    <div
                        className='lg:flex font-move flex-col justify-between hidden bg-move-lgrey py-3 px-8'
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '300px',
                            height: '100%',
                        }}
                    >
                        <div>
                            <a href="/">
                                <img
                                    src={Logo}
                                    alt="logo"
                                    className="mb-10 h-6"
                                />
                            </a>
                            {items.map((item, i) => {
                                const Icon = item.icon;
                                return(
                                    <a
                                        key={i}
                                        href={item.href}
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                        target={item.target}
                                        className= 'text-md tracking-wide my-4 block'
                                    >
                                        <div
                                            className={item.current ? 'text-move-teal font-semibold' : 'text-move-navy font-base'}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '20px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <div className='inline-block text-xs w-6 text-center'><i className={item.icon} /></div>
                                            <div
                                                className='ml-2'
                                            >{item.name}
                                            </div>
                                        </div>
                                    </a>
                                )
                            })}
                        </div>
                        <div>
                            <div className='font-move text-xs font-semibold'>© 2024 Move Health Partners</div>
                            <div className='font-move text-xs'>
                                <a className='text-move-slate no-underline' href='https://movehealth.io/terms'>Terms of Use</a>
                            </div>
                        </div>
                    </div>
                    <div
                        className='nav-profile lg:block hidden'
                        style={{
                            position: 'fixed',
                            top: "25px",
                            right: "25px",
                            zIndex: 100,
                        }}
                    >
                        <i
                            className="fa-solid fa-bars block text-move-navy bg-move-offwhite rounded-full p-2 shadow-sm"
                            onClick={() => setShowHam(true)}
                            style={{
                                fontSize: '22px',
                                cursor: 'pointer',
                            }}
                        />
                        {
                            !isMobile &&

                            <OutsideAlerter
                                onClick={() => setShowHam(false)}
                            >
                                <div 
                                    className="hamburger-menu"
                                    style={{
                                        display: showHam ? "" : "none",
                                    }}
                                >   
                                    {profileItems.map((item, i) => (
                                        <a
                                            className={item.mobileOnly ? 'lg:hidden flex items-center no-underline' : 'items-center no-underline flex'}
                                            key={i}
                                            href={item.href}
                                            onClick={() => item.onClick && item.onClick()}
                                        >
                                            <div className='inline-block text-xs w-6 text-center'><i className={item.icon} /></div>
                                            <div
                                                className='ml-2'
                                            >
                                                {item.name}
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </OutsideAlerter>
                        }
                    </div>
                </>
            }
            {
                (clientShow || isMobile) &&

                <div
                    
                    className='w-full fixed top-0 z-10'
                >
                    <div style={{ maxWidth: '100%' }} className="w-full mx-auto px-4 pt-4 pb-2 shadow-sm bg-white z-10 flex justify-between items-center">
                        <a href="/">
                            <img
                                src={Logo}
                                alt="logo"
                                className='block h-5'
                            />
                        </a>
                        <div
                            className='nav-profile'
                        >
                            <i
                                className="fa-solid fa-bars block bg-move-offwhite rounded-full p-2 shadow-sm"
                                onClick={() => setShowHam(true)}
                                style={{
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                }}
                            />
                            <OutsideAlerter
                                onClick={() => setShowHam(false)}
                            >
                                <div 
                                    className="hamburger-menu"
                                    style={{
                                        display: showHam ? "" : "none",
                                    }}
                                >   
                                    {profileItems.map((item, i) => (
                                        <a
                                            className={item.mobileOnly ? 'lg:hidden flex items-center no-underline' : 'items-center no-underline flex'}
                                            key={i}
                                            href={item.href}
                                            onClick={() => item.onClick && item.onClick()}
                                        >
                                            <div className='inline-block text-xs w-6 text-center'><i className={item.icon} /></div>
                                            <div
                                                className='ml-2'
                                            >
                                                {item.name}
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </OutsideAlerter>
                        </div>
                    </div>
                </div>
            }
        </>
            
    )
}

export default withStuff(NavBar, {
    state: true,
    api: true,
});