import React from 'react';
import { withStuff } from "../hocs";
import { Uploader, SuccessBox, ErrorBox, Loader, Breadcrumb } from '../components';

const PolicyImport = ({ state }) => {
    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
            <Breadcrumb self="Policy Importer"/>
			<div className='xl:flex gap-3 mt-12 items-start'>
				<div className='w-full lg:w-2/3'>
                    <div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4 mb-4'>
                        <SuccessBox success={state.success.import_policies} />
                        <ErrorBox error={state.errors.import_policies} />
                        {
                            state.loading.import_policies &&
                            <>
                                <h2>Importing...</h2>
                                <Loader />
                            </>
                        }
                        <div className='flex justify-between items-start'>
                            <div className='text-lg text-center font-medium'><span className='text-move-blue'>HealthSherpa</span> Policy Importer</div>
                        </div>
                        <Uploader
                            name={`sherpa_policy_import`}
                            type="avatar"
                            accept=".csv"
                            onChange={({ target }) => {
                                api.importPolicies(target.value)
                            }}
                        />
                    </div>
                </div>
			</div>
        </div>
    );
}

export default withStuff(PolicyImport, {
    state: true,
    api: true,
});
