import React from 'react';
import { capitalize } from '../utils';

const PolicyStatus = ({ status="" }) => {

    let color;
    if (status.toLowerCase().includes('pending')) {
        color = 'lpurple';
    } else if (status.toLowerCase() == 'enrolled') {
        color = 'lgreen';
    } else {
        color = 'lred';
    }

    return(
        <span
            className={`bg-move-${color} px-2 py-1 rounded`}
        >
            {status.toUpperCase()}
        </span>
    );

};

export default PolicyStatus;
