import { store } from './store';

class Api {
    baseUrl = '/api/v1/'
    authToken = localStorage.getItem('authToken')
    railsToken = document.getElementsByName('csrf-token')[0].content
    store = store

    get = async (path, { params={}, url=this.baseUrl, method='GET', checkRefresh=true, errorType='standard' } = {}) => {
        if (checkRefresh) await this.maybeRefreshToken();

        const json = await fetch(url  + path + this.querify(params), {
            method,
            headers: {
                'Authorization': 'Bearer ' + this.authToken,
                'X-CSRF-Token': this.railsToken
            }
        });

        return await this.handleResponse(json, errorType);
    }

    querify = (query) => {
        const pairs = Object.keys(query).map(key => {
            return key + '=' + query[key];
        });

        return '?' + pairs.join('&');
    }

    post = async (path, { params={}, url=this.baseUrl, method='POST', checkRefresh=true, errorType='standard' } = {}) => {
        if (checkRefresh) await this.maybeRefreshToken();

        const json = await fetch(url + path, {
            method,
            headers: {
                'Authorization': 'Bearer ' + this.authToken,
                'X-CSRF-Token': this.railsToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params),
        });

        return await this.handleResponse(json, errorType);
    }

    maybeRefreshToken = async () => {
        const tokenExpires = new Date(localStorage.getItem('tokenExpires'));
        const now = new Date();

        if (tokenExpires && now > tokenExpires) {

            const res = await this.get('refresh_token', {
                errorType: 'login',
                checkRefresh: false,
            });

            this.authToken = res.auth_token;
            store.reduce({
                type: 'login',
                token: res.auth_token,
            });

        }
    }

    handleResponse = async (json, errorType) => {
        if (json.status === 401) {

            localStorage.setItem('redirectTo', window.location.href);
            window.location.href = '/login';
            return {};
        
        } else if (json.status === 402) {

            window.location.href = '/subscribe?error=402';
            return {};

        } else if (json.status === 404) {

            window.location.href = '/404';
            return {};

        } else {

            const res = await json.json();
            if (res.error) {
                store.reduce({
                    type: 'error',
                    errorType,
                    error: res.error,
                });
                return { error: true };
            }
            return res;

        }
    }

    requestToken = async () => {
        this.setLoading('login');

        const res = await this.post('request_token', {
            checkRefresh: false,
        });

        if (!res.error) {

            window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${res.token}`;

            return true;

        } else {

            return false;

        }

    }

    accessToken = async (params) => {
        this.setLoading('login');

        const res = await this.post('access_token', {
            params: { 
                ...params,
                affiliate: localStorage.getItem('affiliate'),
                token: localStorage.getItem('token'),
                group_id: localStorage.getItem('group_id'),
            },
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });
            
            window.location.href = '/';
            
            return true;

        } else {

            return false;

        }

    }

    login = async (user) => {
        this.setLoading('login');

        const res = await this.post('login', {
            params: { user },
            errorType: 'login',
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });

            const redirectTo = localStorage.getItem('redirectTo');
            localStorage.removeItem('redirectTo');
            window.location.href = redirectTo || "/";

            return true;

        } else {

            return false;

        }
    }

    getInvite = async (token) => {
        const res = await this.get(`invite/${token}`, {
            errorType: 'login',
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_user',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }


    googleLogin = async (params, redirectTo="/") => {
        this.setLoading('login');

        const res = await this.post('google', {
            params: { 
                fetch: true, 
                ...params,
                affiliate: localStorage.getItem('affiliate'),
                token: localStorage.getItem('token'),
                group_id: localStorage.getItem('group_id'),
            },
            errorType: 'login',
            checkRefresh: false,
        })

        if (!res.error) {

            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });

            window.location.href = redirectTo;

            return true;

        } else {

            return false;

        }
    }

    createUser = async (user) => {
        this.setLoading('login');

        const res = await this.post('sign_up', { 
            params: {
                user: { ...user, token: localStorage.getItem('token') },
            },
            errorType: 'login',
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'login',
                token: res.auth_token,
                user: res.user,
            });

            window.location.href = "/";

            return true;

        } else {

            return false;

        }
    }

    inviteAdvisor = async (user) => {
        this.setLoading('invite_advisor');

        const res = await this.post('users', { 
            params: { user },
            errorType: 'invite_advisor',
        });

        if (!res.error) {

            window.location.href = "/account";
            
            return true;

        } else {

            return false;

        }
    }

    sendConfirmationEmail = async () => {
        const res = await this.post('users/send_confirmation_email', {
            errorType: 'confirmation_email',
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'confirmation_email',
                success: 'Confirmation email resent!',
            });

            return true;
            
        } else {

            return false;

        }

    }

    confirmEmail = async (token) => {
        const res = await this.post('users/confirm_email', {
            params: { token },
            errorType: 'confirm_email',
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'login',
                ...res,
            });

            return true;

        } else {

            return false;
        }
    }

    requestResetPassword = async (email) => {
        this.setLoading('reset_password');

        const res = await this.post('reset_password_email', {
            params: { email },
            errorType: 'reset_password',
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'reset_password',
                success: 'Email sent!',
            });

            return true;

        } else {

            return false;

        }
    }

    changePassword = async (params) => {
        this.setLoading('reset_password');

        const res = await this.post(`reset_password/${params.token}`, {
            params,
            errorType: 'reset_password',
            checkRefresh: false,
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'reset_password',
                success: 'Password changed!',
            });

            return true;

        } else {

            return false;

        }

    }



    signOut = async () => {
        const res = await this.post('sign_out', {
            checkRefresh: false
        });

        if (!res.error) {

            localStorage.clear();
            window.location.href = "/";
            return res.success;

        } else {

            return false;

        }
    }

    updateOnboarding = async (params) => {
        this.post('onboarding', {
            params
        })
    }

    updateSurvey = async (params) => {
        this.post('survey', {
            params
        })
    }


    updateUser = async (id, user, type='update_user') => {
        this.setLoading(type);

        const res = await this.post(`users/${id}`, { 
            params: { user },
            errorType: type,
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_user',
                ...res,
            });

            store.reduce({
                type: 'success',
                successType: 'update_user',
                success: 'Successfully updated',
            });

            return true;

        } else {

            return false;

        }
    }

    updateUserRole = async (id) => {
        this.setLoading('update_user');

        const res = await this.post(`users/${id}/update_role`, { 
            params: { role: 'admin' },
            errorType: 'update_user',
            method: 'POST',
        });

        if (!res.error) {

            window.location.reload();

            return true;

        } else {

            return false;

        }
    }

    updateAvatar = async (id, image, type='avatar') => {
        this.setLoading(type);

        const res = await this.post(`users/${id}/avatar`, { 
            params: { image },
            errorType: type,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_user',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    destroyUser = async (id, type="destroy_user") => {
        const res = await this.get(`users/${id}`, {
            method: 'DELETE',
            errorType: type,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_users',
                users: store.state.users.filter(user => user.id !== id),
            });

            return true;

        } else {

            return false;

        }
    }

    destroyClient = async (id) => {
        const res = await this.destroyUser(id, 'destroy_client');

        if (res) {

            store.reduce({
                type: 'set_clients',
                clients: store.state.clients.filter(client => client.id !== id),
            });
        
            return true;
        
        } else {

            return false;

        }
    }

    
    adminGetUser = async (id) => {
        this.setLoading('user');

        const res = await this.get(`users/${id}`, {
            errorType: 'user',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_other_user',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    getUser = async (params={}) => {
        this.setLoading('user');

        const res = await this.get('current_user', {
            checkRefresh: this.authToken ? true : false,
            errorType: 'user',
        });
        
        if (!res.error) {
            store.reduce({
                type: 'set_user',
                ...res,
            });

            return res.user;

        } else {

            return false;

        }
    }

    getUsers = async (params) => {
        this.setLoading('users');

        const res = await this.get('users', {
            errorType: 'users',
            params,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_users',
                ...res,
            });

            return true;

        } else {

            return false;

        }

    }

    getAdminUsers = async ({ set, ...params }) => {
        this.setLoading('users');

        const res = await this.get('admin/users', {
            errorType: 'users',
            params,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_users',
                set,
                ...res,
            });

            return true;

        } else {

            return false;
            
        }

    }

    getAdminAccounts = async (params) => {
        this.setLoading('accounts');

        const res = await this.get('admin/accounts', {
            errorType: 'accounts',
            params,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_accounts',
                ...res,
            });

            return true;

        } else {

            return false;
            
        }

    }

    getClients = async ({ limit=5, ...params }={}) => {
        this.setLoading('clients');

        const res = await this.get('clients', {
            errorType: 'clients',
            params: { limit, ...params },
        });

        if (!res.error) {
            store.reduce({
                type: 'set_clients',
                ...res,
            });

            return true;

        } else {

            return false;
            
        }

    }

    getClient = async (id) => {
        this.setLoading('client');

        const res = await this.get(`clients/${id}`, {
            errorType: 'client',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_client',
                ...res,
            });

            return true;

        } else {

            return false;

        }

    }

    createClient = async (client) => {
        this.setLoading('create_client');

        const res = await this.post('clients', {
            params: { client },
            errorType: 'create_client',
        });

        if (!res.error) {

            window.location.href = '/';

            return true;

        } else {

            return false;

        }
    }

    sendClientInvite = async (id) => {
        this.setLoading('send_client_invite');
        const res = await this.post(`clients/${id}/send_invite`);

        if (!res.error) {

            return  true;

        } else {

            return false;

        }
    }

    getAccount = async (id=null) => {
        this.setLoading('account');
        const path = id ? `accounts/${id}` : 'accounts';
        const res = await this.get(path, {
            errorType: 'account'
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    updateAccount = async (account, id=null) => {
        this.setLoading('update_account');
        const path = id ? `accounts/${id}` : 'accounts';
        const res = await this.post(path, {
            params: { account },
            errorType: 'update_account',
            method: id ? 'PATCH' : 'POST',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                ...res,
            });

            store.reduce({
                type: 'success',
                successType: 'update_account',
                success: 'Successfully updated your account!',
            });

            return true;

        } else {

            return false;

        }
    }

    cancelSubscription = async (id, comment) => {
        this.setLoading('cancel_subscription');
        
        const res = await this.post(`accounts/${id}/cancel`, {
            params: { comment },
            errorType: 'cancel_subscription',
        });
 
        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'cancel_subscription',
                success: 'Successfully cancelled subscription. You can use still use your account for the remainder of this billing cycle.',
            });

            return true;

        }
    }

    reactivateSubscription = async (id) => {
        this.setLoading('cancel_subscription');

        const res = await this.post(`accounts/${id}/reactivate`, {
            errorType: 'cancel_subscription',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'cancel_subscription',
                success: 'Successfully reactivated subscription!',
            });

            return true;

        } else {

            return false;

        }

    }

    findCoupon = async (account_id, coupon) => {
        this.setLoading('coupon');

        const res = await this.get(`accounts/${account_id}/coupon`, {
            params: { coupon },
            errorType: 'coupon',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_coupon',
                ...res,
            });
            
            return true;
        
        } else {

            return false;

        }
    }
    

    attachPaymentMethod = async (payment_method_id) => {
        this.setLoading('payment_method');

        const res = await this.post(`accounts/payment_method`, {
            params: { payment_method_id },
            errorType: 'payment_method',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_account',
                account: res.account,
            });

            store.reduce({
                type: 'success',
                successType: 'payment_method',
                success: 'Successfully added payment method! Your payment will be drafted at the end of your billing cycle.',
            });

            return true;

        } else {

            return false;

        }
    }

    getActivities = async (id=null) => {
        this.setLoading('activities');

        const path = id ? `clients/${id}/activities` : 'activities';
        
        const res = await this.get(path, {
            errorType: 'activities',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_activities',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    getBooking = async (id=null) => {

        this.setLoading('bookings');

        const path = id ? `bookings/${id}` : 'bookings';

        const res = await this.get(path, {
            errorType: 'bookings'
        });

        if (!res.error) {

            store.reduce({
                type: 'set_booking',
                ...res,
            });

            return true;

        } else {

            return false;

        }

    }

    destroyBooking = async (id) => {
        const res = this.get(`bookings/${id}`, {
            method: 'DELETE',
            errorType: 'bookings',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_booking',
                booking: null,
            });
            
            return true;

        } else {

            return false;

        }
    }

    createDependent = async (userId, dependent) => {
        this.setLoading('create_dependent');

        const res = await this.post(`users/${userId}/dependents`, {
            params: { dependent },
            errorType: 'create_dependent',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_dependents',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    getDependents = async (userId) => {
        this.setLoading('dependents');

        const res = await this.get(`users/${userId}/dependents`, {
            errorType: 'dependents',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_dependents',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    updateDependent = async (id, dependent) => {
        this.setLoading('update_dependent');

        const res = await this.post(`dependents/${id}`, {
            params: { dependent },
            errorType: 'update_dependent',
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_dependents',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    destroyDependent = async (id) => {
        const res = this.get(`dependents/${id}`, {
            method: 'DELETE',
            errorType: 'dependents',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_dependents',
                dependents: store.state.dependents.filter(dependent => dependent.id !== id),
            });
            
            return true;

        } else {

            return false;

        }
    }

    getPolicies = async (userId) => {
        this.setLoading('policies');

        const res = await this.get(`users/${userId}/policies`, {
            errorType: 'policies',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_policies',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    importPolicies = async (signed_id) => {
        this.setLoading('import_policies');

        const res = await this.post('policies/import', {
            params: { signed_id },
            errorType: 'import_policies',
        });

        if (!res.error) {

            store.reduce({
                type: 'success',
                successType: 'import_policies',
                success: 'Policies imported!',
            });

            return true;

        } else {

            return false;

        }
    }

    getCountiesByZip = async (zip) => {
        this.setLoading('counties');
        
        const res = await this.get('estimations/counties', {
            params: { zip },
            checkRefresh: false,
            errorType: 'counties',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_counties',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    createEstimation = async ({ rootEstimator, ...estimation}) => {

        this.setLoading('update_estimation');

        const res = await this.post('estimations', {
            params: { estimation },
            checkRefresh: !rootEstimator,
            errorType: 'update_estimation',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_estimation',
                ...res,
            });

            if (rootEstimator) {
                window.location.href = '/root/estimator/' + res.estimation.id;
            } else {
                window.location.href = '/estimations/' + res.estimation.id;
            }

            return true;

        } else {

            return false;

        }

    }

    updateEstimation = async (id, { rootEstimator, ...estimation }) => {

        this.setLoading('update_estimation');

        const res = await this.post(`estimations/${id}`, {
            params: { estimation },
            errorType: 'update_estimation',
            checkRefresh: !rootEstimator,
            method: 'PATCH',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_estimation',
                ...res,
            });

            return true;

        } else {
                
            return false;
    
        }
    }

    getEstimations = async (params) => {
        this.setLoading('estimations');

        const res = await this.get('estimations', {
            errorType: 'estimations',
            params,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_estimations',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    getEstimation = async (id, checkRefresh=true) => {
            
        this.setLoading('estimation');

        const res = await this.get(`estimations/${id}`, {
            errorType: 'estimation',
            checkRefresh,
        });

        if (!res.error) {

            store.reduce({
                type: 'set_estimation',
                ...res,
            });

            return true;

        } else {

            return false;

        }
    }

    duplicateEstimation = async (id) => {

        const res = await this.get(`estimations/${id}/duplicate`, {
            errorType: 'estimation',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_estimations',
                estimations: [...store.state.estimations, res.estimation],
            });

            return true;

        } else {

            return false;

        }
    }

    destroyEstimation = async (id) => {
        const res = this.get(`estimations/${id}`, {
            method: 'DELETE',
            errorType: 'estimations',
        });

        if (!res.error) {

            store.reduce({
                type: 'set_estimations',
                estimations: store.state.estimations.filter(estimation => estimation.id !== id),
            });
            
            return true;

        } else {

            return false;

        }
    }

    calculateEstimation = async (id) => {
        this.setLoading('estimation');

        const res = await this.get(`estimations/${id}/calculate`, {
            errorType: 'estimation',
        });

        if (!res.error) {

            window.location.href = '/estimations/' + id;

            return true;

        } else {

            return false;

        }
    }

    getClientCsv = async (params) => {
        return await this.download('clients/csv.csv', params);
    }

    download = async (path, params) => {

        const res = await fetch(this.baseUrl  +  path + this.querify(params), {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.authToken,
                'X-CSRF-Token': this.railsToken
            }
        });
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        return url;
    }

    setError = (errorType, error) => {
        store.reduce({
            type: errorType,
            error,
        });
    }

    setLoading = (loadingType) => (
        store.reduce({
            type: 'loading',
            loadingType,
        })
    )

    stopLoading = (loadingType) => (
        store.reduce({
            type: 'stop_loading',
            loadingType,
        })
    )
}

export let api = new Api();
window.api = api;