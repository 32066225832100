import React, { useRef } from 'react';
import { ReferredSvg, ScheduledSvg } from '../utils';


const ActivityCard = ({ href, icon, date,  color, html, type, image_url, lastItem, owner_type, owner_avatar, owner_href }) => {
    const card = useRef();
    
    return(
        <div className='flex items-start'>
            <div
                className={`text-move-${color} text-sm mr-3`}
            >
                <i className={icon} />
            </div>
            <div className='wrap item-details text-sm'>
                <span className='text-sm' dangerouslySetInnerHTML={{ __html: html }}></span>
                <span> on {date}</span>
            </div>
        </div>
    );
};

export default ActivityCard;