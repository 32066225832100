import React, { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';

const Currency = ({ onChange, defaultValue, name, ...props }) => {
    const [amount, setAmount] = useState(defaultValue);

    useEffect(() => {
        setAmount(defaultValue);
    }, [defaultValue]);

    function handleAmountChange(value) {
        setAmount(value);
        onChange && onChange({ target: { name, value }});
    }

    return(
        <>
            <CurrencyInput
                required={props.required}
                prefix="$"
                defaultValue={amount}
                transformRawValue={(value) => value.replace('$', '')}
                onValueChange={handleAmountChange}
            />
            <input
                name={name}
                type="hidden"
                value={amount}
            />
        </>
    );
};

export default Currency;
