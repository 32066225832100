import React, { useState, useEffect } from 'react';
import { Table, Avatar, Inputs, ClientStatus } from '../components';
import { download } from '../utils';
import { withStuff } from "../hocs";

const ClientIndex = ({ state, header="Clients", limit=15, search=false }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [status, setStatus] = useState('');
    const [timeout, saveTimeout] = useState(null);
    const clients = state.clients || [];
    const count = clients.length;

    useEffect(() => {
        if (!firstLoad) {
            if (timeout) clearTimeout(timeout);

            saveTimeout(
                setTimeout(() => {
                    api.getClients({ limit, search: searchTerm, status });
                }, 500)
            );
        };
    }, [ searchTerm ]);


    useEffect(() => {
        if (!firstLoad)  {
            api.getClients({ limit, search: searchTerm, status });
        };
    }, [ status ]);

    const schema = {
        name: {
            label: 'Name',
            children: ({datum}) => {
                return(
                    <a
                        className='flex justify-start space-x-2 items-center cursor-pointer no-underline'
                        href={`/clients/${datum.id}`}
                    >
                        <div className='lg:block hidden' style={{ width: '33px !important'}}>
                            <Avatar
                                user={datum}
                                size={32}
                                onClick={() => window.location.href = `/clients/${datum.id}`}
                            />
                        </div>
                        <div>
                            <div className='text-move-blue text-md font-semibold'>{datum.name}</div>
                        </div>
                    </a>
                );
            }
        },
        client_request_type: {
            label: 'Coverage',
        },
        referred_at: {
            label: 'Referred',
            defaultSort: true,
            descending: true,
            children: ({datum}) => {
                const date = new Date(datum.referred_at);
                return(
                    <div>{date.toLocaleString("en-US", {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                    })}</div>
                );
            }
        },
        health_advisor: {
            label: 'Agent',
        },
        financial_advisor: {
            label: 'Advisor',
        },
        status: {
            label: 'Status',
            children: ({datum}) => {
                return(
                    <>
                        <div class='flex justify-between items-center'>
                            <ClientStatus status={datum.status} />
                            <a
                                className='text-red-500 hover:text-red-700 cursor-pointer ml-2'
                                onClick={() => {
                                    if (window.confirm('Are you sure you want to delete this client?'))
                                        api.destroyClient(datum.id)
                                }}
                            >
                                <span className='text-xs'><i className="fa-solid fa-x" /></span>
                            </a>
                        </div>
                    </>
                );
            }
        }
    };

    if (!state.user.super_admin) {
        delete schema.health_advisor;
    };

    if (!state.user.admin) {
        delete schema.financial_advisor;
    }

    if (!search && count === 0) {
        return null;
    }

    async function downloadClientCsv(e) {
        e.stopPropagation();
        const url = await api.getClientCsv({ search: searchTerm, status });
        download(url, `clients-${Date.now()}.csv`);
    };

    return(
        <div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4'>
            <div className='flex justify-between items-start'>
                <span className='text-lg font-medium'>{header}</span>
                <div className='flex gap-3'>
                    {
                        search &&

                        <a className="rounded-md bg-move-teal text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2" onClick={downloadClientCsv}>
                            <button type="button">
                                <i class="fa-solid fa-file-arrow-down mr-2"></i>Download</button>
                        </a>
                    }
                    <a className="rounded-md bg-move-orange text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2" href="/clients/new">
                        <button type="button">
                            <i class="fa-solid fa-circle-plus mr-2"></i>Refer</button>
                    </a>
                </div>
            </div>
            {
                search &&

                <Inputs
                    inputs={[
                        {
                            type: 'text',
                            name: 'search',
                            placeholder: 'Search',
                            value: searchTerm,
                            onChange: (e) => {
                                setFirstLoad(false);
                                setSearchTerm(e.target.value);
                                
                            }
                        },
                        {
                            type: 'select',
                            name: 'status',
                            placeholder: 'Filter Status',
                            capitalizeOptions: true,
                            allowBlank: true,
                            options: [
                                'referred',
                                'scheduled',
                                'pending',
                                'enrolled',
                                'cancelled',
                                'deferred',
                                'passed',
                                'renewal',
                                'terminated',
                                'unknown',
                                'follow up'
                            ],
                            value: status,
                            onChange: (e) => {
                                setFirstLoad(false);
                                setStatus(e.target.value);
                            },
                        }
                    ]}
                />
            }
            <Table
                schema={schema}
                data={state.clients || []}
            />
            {
                count == limit &&

                <div className='text-center'>
                    <a className='btn-white' href='/clients'>View All</a>
                </div>
            }
        </div>
    )
};

export default  withStuff(ClientIndex, {
    state: true,
    api: true,
    effect: async ({ api, limit }) => {
        api.getClients({ limit });
    }
});