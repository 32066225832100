import React from 'react';
import { Breadcrumb, PolicyCard } from '../components';
import { withStuff } from "../hocs";

const Policies = ({ state }) => {
    return(
        <div className='font-move container'>
            <Breadcrumb self="Plan Information"/>
            {(state.policies || []).map((policy, index) =>
                <PolicyCard key={policy.id} {...policy} />
            )}
        </div>
    );
}

export default withStuff(Policies, {
    api: true,
    state: true,
    dependency: "user",
    loader: "policies",
    effect: ({ api, state, match }) => {

        api.getPolicies(match.params.id || state.user.id);
    }
});
