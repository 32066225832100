import React from 'react';
import { withStuff } from "../hocs";
import { TodoIndex, Booking, AdvisorCard, SuccessBox, Breadcrumb, Policies } from '../components';

const ClientDashboard = ({ state }) => {

    return (
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0 max-w-screen-md '>
            <Breadcrumb self='Dashboard' />
            <div className='xl:flex gap-3 mt-12 items-start'>
                <div className='w-full xl:w-2/3'>
                    <h2 className='text-xl font-bold'>Client Name</h2>
                    <h2 className='text-lg block font-medium mb-4'>{state.user.name}</h2>
                </div>
            </div>
            <SuccessBox success={state.success.local} />
            <TodoIndex todos={state.user.todos} />
            <div className='mb-4'>
                <Policies />
            </div>
            <Booking />
            <div className='flex flex-col rounded-md bg-move-lgrey p-3 shadow-md gap-3'>
                <div className='text-left font-semibold text-xl'>My Advisors</div>
                <div className='flex flex-col text-left text-md leading-1 w-full'>
                    <AdvisorCard
                        copy='MY HEALTH INSURANCE AGENT'
                        advisor={state.user.health_advisor}
                    />
                    <AdvisorCard
                        copy='MY FINANCIAL ADVISOR'
                        advisor={state.user.financial_advisor}
                    />
                </div>
            </div>
        </div>
    );
};

export default withStuff(ClientDashboard, {
    state: true
});