import React, { useState, useEffect } from 'react';
import { Dependent } from '../components';

const EstimationDependents = (props) => {
    const [dependents, setDependents] = useState(props.dependents || []);
    const [add, setAdd] = useState(false);

    const spouseExists = dependents.some(d => d.spouse);


    useEffect(() => {
        setDependents(props.dependents);
    }, [props.dependents]);

    const onSave = (dependent) => {
        setAdd(false);
        setDependents(prev => {
            prev[dependent.index] = dependent;
            props.onUpdate(prev);
            return new Array(...prev);
        })
    }

    const removeDependent = (index) => {
        setDependents(prev => {
            prev.splice(index, 1);
            props.onUpdate(prev);
            return new Array(...prev);
        })
    }

    return(
        <>
            {dependents.map((dependent, i) => (
                <Dependent
                    key={i}
                    index={i}
                    onSave={onSave}
                    dependent={dependent}
                    removeDependent={removeDependent}
                />
            ))}
            {
                dependents.length === 0

                ?   <Dependent
                        index={0}
                        self={true}
                        edit={true}
                        onSave={onSave}
                        removeDependent={removeDependent}
                    />

                :   add

                    ?   <Dependent
                            index={dependents.length}
                            edit={true}
                            onSave={onSave}
                            spouseExists={spouseExists}
                            removeDependent={removeDependent}
                        />

                    :   <div
                            className='text-center rounded-md border-2 border-solid border-move-lslate text-sm font-base hover:shadow-md hover:text-move-orange hover:border-move-orange px-3 py-2'
                            onClick={(e) => {
                                setAdd(true)
                            }}
                        >
                            <i className="fa-solid fa-plus mr-1"/>
                            Dependent
                        </div>

            }
        </>
    )
};

export default EstimationDependents;
