import { firmSizes } from './utils';

export function userParams(user={}) {
    const res = [
        {
            label: 'First Name',
            type: 'text',
            key: 'first_name',
            defaultValue: user.first_name,
        },
        {
            label: 'Last Name',
            type: 'text',
            key: 'last_name',
            defaultValue: user.last_name,
        },
        {
            label: 'Phone',
            type: 'tel',
            key: 'phone_number',
            defaultValue: user.phone_number,
        }
    ];

    if (user.type === 'HealthAdvisorUser') {
        res.push({
            label: 'Cal.com Handle',
            type: 'text',
            key: 'meeting_path',
            defaultValue: user.meeting_path,
        });

        res.push({
            label: 'Bio URL',
            type: 'text',
            key: 'bio_url',
            defaultValue: user.bio_url,
        });
    }

    return res;
};

export function userOnboardingParams(user={}) {
    const params = [
        {
            label: 'First Name',
            type: 'text',
            name: 'first_name',
            required: true,
            defaultValue: user.first_name,
        },
        {
            label: 'Last Name',
            type: 'text',
            name: 'last_name',
            required: true,
            defaultValue: user.last_name,
        },
        {
            label: 'Phone',
            type: 'tel',
            name: 'phone_number',
            required: true,
            defaultValue: user.phone_number,
        }
    ];

    if (user.type === 'AdvisorUser') {
        params.push({
            label: 'How many households do you serve?',
            type: 'number',
            name: 'household_count',
            defaultValue: user.household_count,
        });

        params.push({
            label: 'How did you hear about us?',
            type: 'textarea',
            name: 'how_did_you_hear',
            rows: 5,
            defaultValue: user.how_did_you_hear,
        });
    }

    return params;
};


export function accountParams(account={}, superAdmin=false, healthAdvisorUsers=[]) {
    return [
        {
            label: 'Firm Name',
            type: 'text',
            name: 'name',
            required: true,
            defaultValue: account.name,
        },
        {
            label: 'Firm Association',
            type: 'text',
            name: 'firm_association',
            defaultValue: account.firm_association,
        },
        {
            label: 'Firm Size',
            type: 'select',
            name: 'firm_size',
            options: firmSizes,
            placeholder: "Select from dropdown",
            defaultValue: account.firm_size,
        },
        {
            label: 'Street Address',
            type: 'text',
            name: 'address_attributes.street_1',
            required: true,
            defaultValue: account?.address?.street_1,
        },
        {
            label: 'City',
            type: 'text',
            name: 'address_attributes.city',
            defaultValue: account?.address?.city,
        },
        {
            label: 'State',
            type: 'text',
            name: 'address_attributes.state',
            required: true,
            defaultValue: account?.address?.state,
        },
        {
            label: 'Zip Code',
            type: 'text',
            name: 'address_attributes.zip',
            required: true,
            defaultValue: account?.address?.zip,
        },
        (
            superAdmin ? {
                label: 'Health Advisor',
                type: 'select',
                name: 'health_advisor_id',
                options: (healthAdvisorUsers || []).map(user => [user.name, user.id]),
                defaultValue: account.health_advisor_id,
            } : null
        )
    ].filter(Boolean);
}

