import React from 'react';
import BtnSpinner from './btn_spinner.js';
import { withStuff } from  '../hocs';

const Submit = ({ copy, id, loading, type, style, className="w-full rounded-md bg-move-teal text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2", state }) => {
    loading = loading || state.loading[type];

    if (loading) return <BtnSpinner style={style} className={className} />;

    return(
        <input
            id={id}
            className={className}
            type="submit"
            value={copy}
            style={style}
        />
    );
}

export default withStuff(Submit, {
    state: true,
});