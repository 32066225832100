import React from 'react';
import { withStuff } from "../hocs";
import { parseTimeStamp  } from '../utils';
import { TodoIndex } from '../components';

const Booking = ({ state, api, clientId }) => {    
    async function destroyBooking(reschedule=false) {
        const res = await api.destroyBooking(state.booking.id);

        if (res) {
            if (reschedule) {
                window.location.href =  `/schedule/${clientId || ''}`;
            }
        }
    };


    if  (!state.booking) {
        return(
            <TodoIndex
                todos={[
                    {
                        copy: clientId ? `Book health insurance appointment with ${state.client.name}` : ' Book health insurance appointment with Move Health',
                        header: 'Schedule appointment',
                        href: `/schedule/${clientId || ''}`,
                        color: "orange",
                        text: "white",
                    }
                ]}
            />
        )
    };

    return(
        <>
            {
                !state.user.survey_completed && !clientId &&
                
                <TodoIndex
                    todos={[
                        {
                            header: "Complete your personal profile",
                            href: "/survey",
                            color: "orange",
                            text: "white",
                        }
                    ]}
                />
            }
            <div className='rounded p-3 mb-4 bg-move-lyellow'>
                
                <div className='text-lg font-semibold'>Upcoming Meeting</div>
                <div className='text-base wrap my-2'>{clientId ? 'A' : 'Your a'}ppointment is scheduled for {parseTimeStamp(state.booking.startTime)}.</div>
                <div className="flex items-start gap-3">
                    <a href="#" onClick={() => destroyBooking(true)} className='block no-underline text-move-blue'>Reschedule</a>
                    <a
                        href="#"
                        onClick={() => { 
                            if (window.confirm('Are you sure you want to cancel your appointment?')) {
                                destroyBooking()
                            }
                        }}
                        className='block no-underline text-move-red'
                    >
                        Cancel
                    </a>
                </div>
            </div>
        </>
    );
};

export default withStuff(Booking, {
    api: true,
    state: true,
    loader: 'bookings',
    effect: ({ clientId }) => {
        api.getBooking(clientId);
    }
});
