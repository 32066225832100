import React from 'react';
import { withStuff } from "../hocs";
import { Estimation } from '.';

const PublicEstimation = ({ match }) => {
    return(
        <div className="text-center">
            <Estimation
                isPublic={true}
                match={match}
            />
        </div>
    )
};

export default withStuff(PublicEstimation, {
    api: true,
    loader: 'estimation',
    effect: async ({ api, match }) => {
        api.getEstimation(match.params.id, false);
    }
});
