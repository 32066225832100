import React from 'react';
import { Form, Breadcrumb } from '../components';

const NewAdvisor = () => {
    
    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
            <Breadcrumb self='New Advisor' />
            <div className='flex justify-center mt-12'>
                <div className='max-w-md'>
                    <div className="text-center mb-2 text-2xl font-semibold text-move-teal">Add a new user</div>
                    <Form
                        onSubmit="inviteAdvisor"
                        type="invite_advisor"
                        submitCopy="Invite"
                        col='12'
                        inputs={[
                            {
                                label: 'First name',
                                name: 'first_name',
                            },
                            {
                                label: 'Last name',
                                name: 'last_name',
                            },
                            {
                                label: 'Email',
                                type: 'email',
                                name: 'email',
                            },
                            {
                                label: 'Role',
                                type: 'select',
                                name: 'role',
                                options: [
                                    'admin',
                                    'member',
                                ]
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    )
};

export default NewAdvisor;
