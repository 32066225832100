import React from 'react';
import { PaymentPlan, ErrorBox } from '../components';
import { withStuff } from '../hocs';

const Subscribe = ({ state, query }) => {
    const isAdvisor = state.user && state.user.type === 'AdvisorUser';

    if  (!isAdvisor) return(
        <ErrorBox error="You must be an advisor to view this page." />
    );

    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
            <div className='text-move-navy uppercase text-sm font-normal my-4'>Subscribe</div>
            <div className='mt-12'>
                {
                    query.error == '402' && (
                        <ErrorBox error="Your free trial has expired. Please subscribe to a plan to continue.
                        If you have questions, please email info@movehealthpartners.com." />
                    )
                }
                <PaymentPlan
                    afterUpdate={() => window.location.href = "/"}
                />
            </div>
        </div>
    );
};

export default withStuff(Subscribe, {
    api: true,
    state: true,
    query: true,
    loader: 'account',
    effect: ({ api }) => {
        api.getAccount();
    }
});
