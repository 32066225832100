import React, { useContext, useEffect } from "react";
import { Loader, ErrorBox } from "../components";
import Context from "../context";
import { parseQuery } from "../utils";
import { usePageHeight } from "../hooks";

const withStuff = (Component, config) => {
  return (props) => {
    const { state, api } = useContext(Context);
    const newProps = { ...props };
    if (config.state) newProps.state = state;
    if (config.api) newProps.api = api;
    if (config.query) newProps.query = parseQuery(props.location.search);

    const pageHeight = usePageHeight();
    newProps.pageHeight = pageHeight;

    let dependencyLoading = false;
    if (config.dependency) {
      if (Array.isArray(config.dependency)) {
        config.dependency.forEach((dep) => {
          if (state.loading[dep]) dependencyLoading = true;
        });
      } else {
        dependencyLoading = state.loading[config.dependency];
      }
    }

    let loading = false;
    if (config.loader) {
      if (Array.isArray(config.loader)) {
        config.loader.forEach((loader) => {
          if (state.loading[loader]) loading = true;
        });
      } else {
        loading = state.loading[config.loader];
      }
    }

    useEffect(() => {

      if (config.effect && !dependencyLoading) config.effect(newProps);

    }, [ dependencyLoading ]);
    

    if (config.loader || config.dependency) {
      const error = state.errors[config.loader];

      if (loading && config.emptyOnLoading) return null;
    
      if (loading)
        return (
          <div className="container">
            <Loader />
          </div>
        );

      if (error)
        return (
          <div className="container">
            <ErrorBox error={error} {...config.error} />
          </div>
        );
    }

    return <Component {...newProps} />;
  };
};

export default withStuff;
