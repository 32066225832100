import React, { useState, useEffect, useRef } from 'react';
import { Inputs, SimpleForm, Submit } from '../components';

const QuestionFlow = ({ defaultStep=0, steps, onStepChange, onComplete }) => {
    const [step, setStep] = useState(defaultStep);
    const form = useRef();

    useEffect(() => {
        onStepChange && onStepChange(step);
    }, [ step ]);

    const currentStep = steps[step];

    async function handleSubmit(params) {
        let res;

        if (currentStep.onSubmit) {
            res = await currentStep.onSubmit(params);
        } else {
            res = true;
        }

        if (res) {
            if (step === steps.length - 1) {
                onComplete && onComplete();
            } else {
                setStep(step + 1);
            };
        }
    };

    const Buttons = () => (
        <div className='flex gap-3 mt-2'>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    setStep(step => step == 0 ? 0 : step - 1);
                }}
                style={{ width: '100px'}}
                className='flex-1 bg-move-lslate text-move-slate rounded p-2 mt-3'
            >
                Back
            </button>

            <Submit
                id='question-flow-submit'
                style={{ width: '100px'}}
                type={currentStep.type}
                className='flex-1 bg-move-orange text-white rounded p-2 w-full mt-3'
                copy="Next &#8594;"
            />
        </div>
    );


    return(
        <>
            <h2 className='text-center mb-3 text-xl font-semibold text-move-teal'>{currentStep.header}</h2>
            <p className='text-center mb-4 text-move-navy font-light'>{currentStep.subheader}</p>
            <SimpleForm onSubmit={handleSubmit} _ref_={form}>
                {
                    currentStep.child

                    ?   currentStep.child()

                    :   <Inputs
                            col="12"
                            inputs={currentStep.inputs}
                            key={JSON.stringify(currentStep.inputs)}
                        />
                }
                <p className='text-center text-move-navy font-light text-xs mb-3'>{currentStep.postInputCopy}</p>
                <Buttons />
            </SimpleForm>
        </>
    );
};

export default QuestionFlow;


