import React, { useState, useEffect } from 'react';
import { withStuff } from '../hocs';
import { Inputs, ErrorBox, SuccessBox } from '.';
import { toUSD } from '../utils';

const Coupon = ({ state, api }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [timeout, saveTimeout] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (!firstLoad) {
            if (timeout) clearTimeout(timeout);

            saveTimeout(
                setTimeout(() => {
                    api.findCoupon(state.account.id, searchTerm);
                }, 500)
            );
        };
    }, [ searchTerm ]);

    function amountOrPercentageOff(coupon) {
        if (coupon.amount_off) {
            return `${toUSD(coupon.amount_off / 100)} off`
        } else {
            return `${coupon.percent_off}% off`;
        }
    }

    function applyCoupon() {
        if (state.coupon) {
            api.updateAccount({ 
                coupon_id: state.coupon.id,
                applied_coupon_amount: state.coupon.amount_off,
                applied_coupon_percent: state.coupon.percent_off,
            }, state.account.id);

            setSearchTerm('');
            setSuccess(true);
        }
    }

    return(
        <>
            <div className="text-lg font-medium mt-3 pt-3 mb-3">Apply Discount Code</div>
                
            <div className="card p-3"> 
                <Inputs 
                    col='12'
                    inputs={[
                        {
                            name: 'coupon',
                            label: 'Discount Code',
                            placeholder: 'Enter discount code',
                            type: 'text',
                            onChange: ({ target }) => {
                                setFirstLoad(false);
                                setSearchTerm(target.value);
                            }
                        },
                    ]}
                />
                <ErrorBox error={state.errors.coupon} />
                {
                    state.coupon &&

                    <div>
                        <strong>{state.coupon.name}</strong> - {amountOrPercentageOff(state.coupon)}
                    </div>
                }
                {
                    success &&

                    <SuccessBox success='Successfully applied coupon!' />
                }
                <button
                    onClick={applyCoupon}
                    className='w-full mt-3 rounded-md bg-move-slate text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2'
                >
                    Apply Discount
                </button>

            </div>
        </>
    );
}

export default withStuff(Coupon, {
    api: true,
    state: true,
});