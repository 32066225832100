import React from 'react';
import { withStuff } from "../hocs";
import { Table, Breadcrumb } from '../components';

const schema = {
    name: {
        textAlign: 'left',
        label: 'Account Name',
        children: ({ datum }) => (
            <a className='text-move-blue text-md font-semibold no-underline' href={`/accounts/${datum.id}`}>{datum.name}</a>
        )
    },
    health_advisor: {
        label: 'Assigned Owner',
        textAlign: 'left',
    },
    start_date: {
        label: 'Start Date',
        defaultSort: true,
        descending: true,
        children: ({datum}) => {
            const date = new Date(datum.created_at);
            return(
                <div>{date.toLocaleString("en-US", {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                })}</div>
            );
        }
    },
}
const Account = ({ api, state }) => {

    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
			<Breadcrumb self='Accounts' />
			<div className='xl:flex gap-5 mt-12 items-start'>
				<div className='w-full lg:w-2/3'>
					<div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4'>
						<div className='flex justify-between items-start'>
							<span className='text-lg font-medium'>All Move Health Accounts</span>
                        </div>
                        <Table
                            schema={schema}
                            data={state.accounts}
                        />
                    </div>
				</div>
			</div>
		</div>
    );
};

export default withStuff(Account, {
    api: true,
    state: true,
    loader: 'accounts',
    effect: ({ api }) => {
        api.getAdminAccounts();
    }
});
