import React from 'react';
import { withStuff } from "../hocs";

const CancelSubscription = ({ api, state }) => {

    async function handleCancel() {
        const comment = window.prompt('Are you sure you want to cancel? If so, please provide a reason the reason for cancellation.');

        if (comment) {
            await api.cancelSubscription(state.account.id, comment);
        };
    }
    
    if (state.account.cancel_at) {
        return(
            <div className='mt-4 pt-2'>
                {
                    state.account.cancelled

                    ?   <div className='mb-2'>
                            <span className='text-move-slate'>Your subscription has been cancelled.</span>
                        </div>
                    
                    :   <div className='mb-2'>
                            <span className='text-move-slate'>Your subscription is scheduled to be cancelled on {state.account.cancel_at}.</span>
                        </div>
                }
                <span
                    onClick={() => api.reactivateSubscription(state.account.id)}
                    className='underline inline-block text-move-blue cursor-pointer'
                >
                    Reactivate Move Subscription
                </span>
            </div>
        )
    } else {
        return(
            <span
                onClick={handleCancel}
                className='underline inline-block text-move-blue cursor-pointer mt-5'
            >
                Cancel Move Subscription
            </span>
        )
    }
}

export default withStuff(CancelSubscription, {
    api: true,
    state: true,
    loader: 'cancel_subscription',
});