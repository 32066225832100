import React from 'react';
import { Breadcrumb, QuestionFlow, Dependents } from '../components';
import { withStuff } from "../hocs";

const Survey = ({ state, api }) => {    
    function handleChange(step) {
        api.updateSurvey({
            survey_step: step
        });
    };

    let steps = [
        {
            inputs: [
                {
                    type:  'date',
                    label: 'Birthdate',
                    name:  'birthdate',
                    required: true,
                    defaultValue: state.user.birthdate,
                },
                {
                    type: 'select',
                    label: 'Sex',
                    name: 'sex',
                    required: true,
                    options: ['Male', 'Female'],
                    placeholder: 'Choose your sex',
                    defaultValue: state.user.sex,
                },
                {
                    type: 'text',
                    label: 'Zip Code',
                    name: 'zip_code',
                    placeholder: 'Enter your zip code',
                    required: true,
                    defaultValue: state.user.zip_code,
                },
            ],
            onSubmit: async (params) => {
                return await api.updateUser(state.user.id, params);
            },
            type: 'update_user',
        },
        {
            header: 'Who needs coverage?',
            child: () => {
                return(
                    <Dependents />
                );
            }
        },
        {
            label: 'Household Information',
            type: 'update_user',
            onSubmit: async (params) => {
                return await api.updateUser(state.user.id, params);
            },
            inputs: [
                {
                    type: 'number',
                    name: 'taxable_dependents',
                    label: 'How many people are your tax household?',
                    tooltip: 'This is the number of people you put on your tax return.',
                    defaultValue: state.user.taxable_dependents,
                    required: true,
                },
                {
                    type: 'currency',
                    name: 'household_income',
                    tooltip: 'Modified Adjusted Gross Income (MAGI) is adjusted gross income (AGI) plus any untaxed foreign income, Social Security benefits, and tax-exempt interest. For most people, its the same as their adjusted gross income (AGI).',
                    label: 'Household income (MAGI)',
                    defaultValue: state.user.household_income,
                    required: true,
                }
            ]
        },
        {
            header: 'Preferred providers',
            type: 'update_user',
            onSubmit: async (params) => {
                return await api.updateUser(state.user.id, params);
            },
            inputs: [
                {
                    type: 'textarea',
                    rows: 7,
                    placeholder: 'List any preferred doctors, specialists, hospitals, or pharmacies you’d like to have covered.',
                    name: 'preferred_providers',
                    defaultValue: state.user.preferred_providers,
                    required: true,
                }
            ]
        },
        {
            header: 'Medications',
            type: 'update_user',
            onSubmit: async (params) => {
                return await api.updateUser(state.user.id, params);
            },
            inputs: [
                {
                    type: 'textarea',
                    rows: 7,
                    placeholder: 'List all the prescription medications in your household. Please include the name, dosage, and frequency for each medication.',
                    name: 'medications',
                    defaultValue: state.user.medications,
                    required: true,
                }
            ]
        }
    ];

    async function onComplete() {
        await api.updateSurvey({
            survey_completed: true
        });
        localStorage.setItem('success', 'Thanks for completing your profile!')
        setTimeout(() => {
            window.location.href = '/';
        }, 500);
    }


    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
            <Breadcrumb self="Complete Profile"/>
            <div className='flex justify-center mt-12'>
                <div className='max-w-md'>
                    <h2 className="text-xl text-center font-bold mb-3">About You</h2>
                    <QuestionFlow
                        defaultStep={state.user.survey_step}
                        steps={steps}
                        onStepChange={handleChange}
                        onComplete={onComplete}
                    />
                </div>
            </div>
        </div>
    );
}

export default withStuff(Survey, {
    api: true,
    state: true,
    loader: "user",
});
