import React from 'react';

const TodoIndex = ({ todos=[], hrefPrefix="" }) => {

    return(
        <>
            {(todos || []).map((todo, i) => (
                <a key={i} className='no-underline mt-12' href={hrefPrefix + (todo.href || "#")} >
                    <div className={`flex justify-between items-center p-3 mb-4 cursor-pointer text-move-${todo.text} rounded-md bg-move-${todo.color} ${todo.class}`}>
                        <div>
                            <div className='text-lg font-semibold'>{todo.header}</div>
                            <div className='text-base'>{todo.copy}</div>
                        </div>
                        {
                            todo.href && <i className="fa-regular fa-circle-right text-xl" />
                        }
                    </div>
                </a>
            ))}
        </>
    );
};

export default TodoIndex;
