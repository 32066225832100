import React from 'react';
import { withStuff } from "../hocs";

const Avatar = ({ user, size=40, onClick, className }) => {

    if (!user?.id)
        return null;

    let url = `https://ui-avatars.com/api/?name=${user.first_name}+${user.last_name}&color=fff&background=${user.avatar_background}`;

    if (user.avatar?.url)
        url = user.avatar.url;

    return(
        <img
            className={`rounded-full cursor-pointer ${className}`}
            onClick={onClick}
            src={url}
            alt="avatar"
            height={size}
            width={size}
        />
    );
};

export default withStuff(Avatar, {
    state: true,
});
