import React, { useRef, useContext } from 'react';
import { 
    ErrorBox, 
    SuccessBox, 
    Submit, 
    Inputs,
} from '.';
import Context from '../context';
import { parseInputValues } from '../utils';

const Form = ({ inputs=[], _ref_, className, onSubmit, submitCopy, submitClass, type, col="6", children }) => {
    const { api, state } = useContext(Context);
    const form = useRef();

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const formData = new FormData((_ref_ || form).current);
        let params = {};
        for (let pair of formData.entries()) {
            params[pair[0]] = pair[1];
        };

         if (typeof onSubmit == "string")
            onSubmit = api[onSubmit];
        
        return onSubmit(parseInputValues(params));
    }

    function renderInputs(inputs) {
        return <Inputs inputs={inputs} col={col} errors={state.errors} />;
    }

    const loading = state.loading[type];
    const error = state.errors[type];
    const success = state.success[type];

    return(
        <div className={`${className}`}>
            <form ref={_ref_ || form} onSubmit={handleSubmit} >
                <div className='mb-3'>
                    <ErrorBox error={error} />
                    <SuccessBox success={success} />
                </div>
                {renderInputs(inputs)}
                {children}
                <div className='flex justify-end'>
                    <Submit
                        copy={submitCopy}
                        className={submitClass}
                        loading={loading}
                        style={{marginTop: '10px'}}
                    />
                </div>
            </form>
        </div>
    );
};

export default Form;