import React from 'react';
import { Table, Breadcrumb} from '../components';
import { withStuff } from "../hocs";

const schema = {
	name: {
		textAlign: 'left',
		children: ({ datum }) => (
            <a className='text-move-blue text-md font-semibold no-underline' href={`/profile/${datum.id}`}>{datum.name}</a>
        )
	},
	email: {
		label: 'Email',
		textAlign: 'left',
	},
	account_name: {
		label: 'Account',
		textAlign: 'left',
		children: ({ datum }) => (
			<a className='text-move-blue text-md font-semibold no-underline' href={`/accounts/${datum.account_id}`}>{datum.account_name}</a>
		)
	},
	start_date: {
		label: 'Start Date',
		defaultSort: true,
		descending: true,
		children: ({datum}) => {
			const date = new Date(datum.created_at);
			return(
				<div>{date.toLocaleString("en-US", {
					month: 'short',
					day: 'numeric',
					year: 'numeric'
				})}</div>
			);
		}
	},

}

const Clients  = ({ api, state }) => {

	return(
		<div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
			<Breadcrumb self='Users' />
			<div className='xl:flex gap-3 mt-12 items-start'>
				<div className='w-full lg:w-2/3'>
					<div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4'>
						<div className='flex justify-between items-start'>
							<span className='text-lg font-medium'>All Move Health Users</span>
						</div>
						<Table
							schema={schema}
							data={state.users}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withStuff(Clients, {
	state: true,
	api: true,
	loader: 'users',
	effect: ({ api }) => {
		api.getAdminUsers({ set: 'users' });
	}
});