import React from 'react';

const ErrorBox = ({ error }) => {
    if (error) return(
        <div className="items-center bg-move-lred flex gap-2 text-move-red p-3 text-xs md:text-sm rounded">
            {error}
        </div>
    );

    return <div></div>;
}

export default ErrorBox;