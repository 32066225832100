import React from 'react';
import { PolicyStatus } from '../components';
import { toUSD } from '../utils';

const PolicyCard = ({ plan, ...policy }) => {
    return(
        <div className='bg-move-lgrey rounded p-3 mt-4'>
            <div className='flex items-center justify-between rounded mb-3'>
                <p className='font-bold'>{policy.policy_type.toUpperCase()} PLAN</p>
                <PolicyStatus status={policy.policy_status} />
            </div>

            <div className='flex items-end justify-between mt-2'>
                <div>Carrier</div>
                <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                <strong>{policy.issuer_name}</strong>
            </div>
            {
                policy.policy_type.toLowerCase() === 'aca' &&

                <>
                    <div className='flex items-end justify-between mt-2'>
                        <div>Plan Name</div>
                        <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                        <div>{policy.plan_name}</div>
                    </div>
                    <div className='flex items-end justify-between mt-2'>
                        <div>Metal Level</div>
                        <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                        <div>{policy.metallic_level}</div>
                    </div>
                    <div className='flex items-end justify-between mt-2'>
                        <div>Covered Lives</div>
                        <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                        <div>{policy.covered_lives}</div>
                    </div>
                </>

            }
            
            <div className='flex items-end justify-between mt-2'>
                <div>Effective Date</div>
                <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                <div>{policy.effective_date}</div>
            </div>
            <div className='flex items-end justify-between mt-2'>
                <div>Monthly Premium</div>
                <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                <div>{toUSD(policy.premium)}</div>
            </div>
            {
                policy.policy_type.toLowerCase() === 'aca' &&

                <>
                    <div className='flex items-end justify-between mt-2'>
                        <div>Individual Deductible</div>
                        <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                        <div>{toUSD(policy.deductible)}</div>
                    </div>
                    <div className='flex items-end justify-between mt-2'>
                        <div>Family Deductible</div>
                        <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                        <div>{toUSD(policy.deductible * 2)}</div>
                    </div>
                    <div className='flex items-end justify-between mt-2'>
                        <div>Individual Max Out-of-Pocket</div>
                        <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                        <div>{toUSD(policy.moop)}</div>
                    </div>
                    <div className='flex items-end justify-between mt-2'>
                        <div>Family Max Out-of-Pocket</div>
                        <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
                        <div>{toUSD(policy.moop * 2)}</div>
                    </div>

                    <div className='flex items-end justify-between mt-5'>
                        <div>Summary of Benefits</div>
                        <a href={plan.summary_of_benefits_url} target='_blank' rel='noreferrer noopener' className='text-move-blue'>
                            <i className="fa-solid fa-arrow-up-right-from-square" />
                        </a>
                    </div>
                </>
            }
        </div>
    );
}

export default PolicyCard;
