import React, { useEffect } from 'react';
import { withStuff } from "../hocs";
import { Form, AdvisorIndex, PaymentPlan, Breadcrumb } from '../components';
import { accountParams } from '../params';

const Account = ({ api, state }) => {
    const superAdmin = state.user && state.user.super_admin;

    useEffect(() => {
        if (state.user?.super_admin) {
            api.getAdminUsers({ type: 'HealthAdvisorUser', set: 'health_advisor_users' })
        }
    }, [ state.user ]);

    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
			<Breadcrumb self='Account' />
			<div className='xl:flex gap-5 mt-12 items-start'>
				<div className='w-full lg:w-2/3'>
					<div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4 mb-4'>
                        <div className='flex justify-between items-start'>
							<span className='text-lg font-medium'>Account Info</span>
                        </div>
                        <Form
                            onSubmit={params => api.updateAccount(params, state.account.id)}
                            type="update_account"
                            inputs={accountParams(state.account, superAdmin, state.health_advisor_users)}
                            submitCopy="Save"
                        />
                    </div>
                    <div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4 mb-4'>
                        <AdvisorIndex />
                    </div>
                    
                    {
                        state.account.stripeable &&

                        <div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4 mb-4'>
                            <PaymentPlan />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default withStuff(Account, {
    api: true,
    state: true,
    loader: 'account',
    effect: ({ api, state, match }) => {
        api.getAccount(match.params.id);
    }
});