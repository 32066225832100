import React, { Component, createContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Login, SignUp, ConfirmEmail } from "../src/pages";
import Routes from "../src/routes";
import { store } from '../src/store';
import { api } from '../src/api';
import Context from '../src/context';
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(window.stripe_public_key);

class Root extends Component {
  constructor(props) {
      super(props);
      this.store = store;
      this.state = this.store.state;
      this.store.setStateHandler(this.setState.bind(this));
  }

  // bootIntercom(user) {
  //   if (user.id)
  //     window.Intercom('boot', {
  //       app_id: 'ui27tgvx',
  //       email: user.email,
  //       user_id: user.id,
  //       created_at: user.created_at,
  //       user_hash: user.intercom_hmac,
  //     });
  // }

  signOut = () => {
    localStorage.clear();
    window.location.href = "/";
  }


  render() {

    return (
      <Context.Provider value={{ api, state: this.state }} >
        <Elements stripe={stripePromise}>
          <Router>
            <Switch>
              <Route exact path="/signin" component={Login}/>
              <Route exact path="/signup" component={SignUp}/>
              <Route exact path="/confirm_email/:token" component={ConfirmEmail} />
              <Route component={Routes}/>
            </Switch>
          </Router>
        </Elements>
      </Context.Provider>
    );
  }
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Root />,
    document.body.appendChild(document.createElement('div')),
  )
});
