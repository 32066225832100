import React from 'react';

const numbers = [1,2,3,4,5,6,7,8,9];
const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');
const caps = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

const conditions = [
    {
        condition: ({ password }) => password.length > 7,
        copy: 'Contains 8 or more characters',
    },
    {
        condition: ({ password }) => numbers.some((n) => password.includes(n)),
        copy: 'Contains at least 1 number',
    },
    {
        condition: ({ password }) => letters.some((n) => password.includes(n)),
        copy: 'Contains at least 1 letter',
    },
    {
        condition: ({ password }) => caps.some((n) => password.includes(n)),
        copy: '1 letter is uppercase',
    },
    {
        condition: ({ password, passwordCopy }) => password.length > 0 && password === passwordCopy,
        copy: 'Both passwords match',
    }
];

const Conditional = ({ condition, copy }) => (
    condition 
        ?   <div 
                className="flex gap-1 items-center text-move-green text-xs"
            >
                <i className="fa-solid fa-circle-check icon"/>
                <span>{copy}</span>
            </div>

        :   <div
                className="flex gap-1 items-center text-move-red text-xs"
            >
                <i className="fa-regular fa-circle-xmark icon"/>
                <span>{copy}</span>
            </div>
);

const PasswordConditions = ({ password, passwordCopy }) => (
    <div className="password-conditions mb-3 pl-2">
        {
            conditions.map((condition, i) => 
                <Conditional 
                    key={i}
                    copy={condition.copy} 
                    condition={condition.condition({password, passwordCopy})} 
                />
            )
        }
    </div>
);

function allConditionsPass(password, passwordCopy) {
    return conditions.every(({ condition }) => 
        condition({ password, passwordCopy })
    );
}

PasswordConditions.allConditionsPass = allConditionsPass;

export default PasswordConditions;