class Store {
    constructor() {
        this.state = {
            current_user_id: null,
            current_user_profile_picture: null,
            user: {
                email_confirmed: true,
                onboarding_complete: true,
                super_admin: localStorage.getItem('superAdmin') === 'true',
                type: localStorage.getItem('userType'),
            },
            users: [],
            loading: {
                user: true,
                clients: true,
                users: true,
                account: true,
                bookings: true,
                client: true,
                estimations: true,
            },
            booking: {},
            account: {},
            accounts: [],
            dependents: [],
            policies: [],
            counties: [],
            estimation: {},
            errors: {
                local: localStorage.getItem('error'),
            },
            success: {
                local: localStorage.getItem('success'),
            },
            searchResults: {},
            scrapeResult: {},
        }

        localStorage.removeItem('success');
        localStorage.removeItem('error');

        this.setState = () => {};
    }

    setStateHandler(setState) {
        this.setState = setState;
    }

    reduce(event) {
        console.log(event);

        switch (event.type) {
        case 'login':
            this.state.loading.login = false;
            localStorage.setItem('authToken', event.token);
            localStorage.setItem('tokenExpires', this.tenMinFromNow());
            if (event.user) {
                localStorage.setItem('userType', event.user.type);
                localStorage.setItem('superAdmin', event.user.super_admin);
            }
            this.state.user = { ...event.user, ...this.state.user };
            break;
        case 'set_users':
            this.state.loading.users = false;
            this.state[event.set || 'users'] = event.users;
            this.state.userCount = event.count;
            break;
        case 'set_user':
            this.state.loading.login = false;
            this.state.loading.user = false;
            this.state.loading.update_user = false;
            if (event.user) {
                localStorage.setItem('userType', event.user.type);
                localStorage.setItem('superAdmin', event.user.super_admin);
            }
            this.state.user = { ...this.state.user, ...event.user };
            this.state.current_user_id = event.user.id;
            this.state.current_user_profile_picture = event.current_user_profile_picture || this.state.current_user_profile_picture;
            break;
        case 'set_other_user':
            this.state.loading.user = false;
            this.state.other_user = event.user;
            break;
        case 'set_account':
            this.state.loading.account = false;
            this.state.loading.cancel_subscription = false;
            this.state.loading.update_account = false;
            this.state.loading.payment_method = false;
            this.state.account = { ...this.state.account, ...event.account };
            break;
        case 'set_accounts':
            this.state.loading.accounts = false;
            this.state.accounts = event.accounts;
            break;
        case 'set_clients':
            this.state.loading.clients = false;
            this.state.clients = event.clients;
            break;
        case 'set_client':
            this.state.loading.client = false;
            this.state.loading.update_client = false;
            this.state.client = { ...this.state.client, ...event.client };
            break;
        case 'set_activities':
            this.state.loading.activities = false;
            this.state.activities = event.activities;
            break;
        case 'set_booking':
            this.state.loading.bookings = false;
            this.state.booking = event.booking;
            break;
        case 'set_dependents':
            this.state.loading.dependents = false;
            this.state.loading.create_dependent = false;
            this.state.dependents = event.dependents;
            break;
        case 'set_policies':
            this.state.loading.policies = false;
            this.state.policies = event.policies;
            break;
        case 'set_counties':
            this.state.loading.counties = false;
            this.state.counties = event.counties;
            this.state.zip = event.zip;
            this.state.state = event.state;
            break;
        case 'set_estimations':
            this.state.loading.estimations = false;
            this.state.estimations = event.estimations;
            break;
        case 'set_estimation':
            this.state.loading.estimation = false;
            this.state.loading.create_estimation = false;
            this.state.loading.update_estimation = false;
            this.state.estimation = event.estimation;
            break;
        case 'set_coupon':
            this.state.loading.coupon = false;
            this.state.coupon = event.coupon;
            break;
        case 'error':
            this.state.loading[event.errorType] = null;
            this.state.success[event.errorType] = null;
            this.state.errors[event.errorType] = event.error;
            break;
        case 'loading':
            this.state.success[event.loadingType] = null;
            this.state.errors[event.loadingType] = null;
            this.state.loading[event.loadingType] = true;
            break;
        case 'stop_loading':
            this.state.loading[event.loadingType] = false;
            break;
        case 'success':
            this.state.errors[event.successType] = null;
            this.state.loading[event.successType] = null;
            this.state.success[event.successType] = event.success;
            break;
        case 'clear_success':
            this.state.success = {};
            break;
        default:
            break;
        };
        this.setState(this.state);
    }

    tenMinFromNow() {
        return new Date(new Date().getTime() + 600000);
    }

    currentList = (type='user') => {
        const tab = this.currentTab(type);
        if (tab) return tab[this.state.tabIndex];
    }

    currentTab = (type='user') => this.getTab(this.state.tab, type);

    getTab = (_tab_, type='user') => {
        const res = this.state[type].tabs.find((({ tab }) => _tab_ === tab));
        if (res) return res.sub_tabs;
    }

    findIndexFromType = (tabType, type='user') => {
        return this.currentTab().findIndex(({ type }) => type === tabType) || 0;
    }
}

export let store = new Store();
