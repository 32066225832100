import React from 'react';
import { EstimationForm } from '.';
import Logo from '../assets/logo.svg';
import RootLogo from '../assets/root.svg';

const RootEstimation = ({ match}) => {
    return(
        <div>
            <div className="w-full p-4 shadow-sm bg-white items-center">
                <div style={{maxWidth: '1200px'}} className='mx-auto flex justify-between items-center'>
                    <div className='flex items-center'>
                        <a href="https://www.rootfinancialpartners.com/">
                            <img
                                src={RootLogo}
                                alt="logo"
                                height="auto"
                                width="100px"
                                className='block'
                            />
                        </a>
                        <div style={{width: '1px'}} className='bg-black h-8 mx-4'></div>
                        <a href="https://www.movehealthpartners.com">
                            <img
                                src={Logo}
                                alt="logo"
                                height="auto"
                                width="100px"
                                className='block'
                            />
                        </a>
                    </div>
                    <div className='flex justify-end items-center'>
                        <a className="text-move-teal text-sm font-bold hover:text-move-navy mr-3" target="_blank" href="https://cal.com/team/move-health-partners/intro-conversation?month=2024-08">
                            <button type="button">Schedule Meeting</button>
                        </a>
                        <a className="rounded-md bg-move-orange text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2" target="_blank" href="https://www.movehealthpartners.com/">
                            <button type="button">Visit Move Health</button>
                        </a>
                    </div>
                </div>
            </div>
            <div style={{maxWidth: '1200px', padding: '0px 10px', margin: '0 auto'}}>
                <EstimationForm match={match} rootEstimator={true} />
            </div>
        </div>
    )
}

export default RootEstimation;
