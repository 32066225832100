import React from 'react';
import { withStuff } from "../hocs";

const SuccessBox = ({ success }) => {

    if (success) return(
        <div className="items-center bg-move-lgreen flex gap-2 text-move-green p-3 text-xs md:text-sm rounded">
            <div>{success}</div>
        </div>
    );

    return <div></div>;
}

export default withStuff(SuccessBox, {
    api: true,
});