import React from 'react';
import { withStuff } from "../hocs";
import { ActivityCard } from '../components'

const ActivityIndex = ({ state }) => {
    const activities = state.activities || [];

    if (activities.length === 0) {
        return null;
    }
    
    return(
        <div className='rounded-md shadow-sm bg-move-lgrey p-3 grid gap-3'>
            <h2 className='text-lg font-medium'>Recent Activity</h2>
            <div className='grid gap-3 grid-cols-1'>
                {activities.map(activity =>
                <ActivityCard
                    key={activity.id}
                    {...activity}
                />
            )}
            </div>
        </div>
    );
}

export default withStuff(ActivityIndex, {
    api: true,
    state: true,
    loader: 'activities',
    effect: async ({ api, clientId }) => {
        api.getActivities(clientId);
    }
});
