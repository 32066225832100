import React from 'react';
import { PolicyCard } from '../components';
import { withStuff } from "../hocs";

const Policies = ({ state }) => {
    return(
        <div className='font-move'>
            {(state.policies || []).map((policy, index) =>
                <PolicyCard key={policy.id} {...policy} />
            )}
        </div>
    );
}

export default withStuff(Policies, {
    api: true,
    state: true,
    dependency: "user",
    loader: "policies",
    effect: ({ api, state, }) => {
        api.getPolicies(state.user.id);
    }
});
