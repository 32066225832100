import React, { useState, useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ErrorBox, Loader, SuccessBox, Submit } from '../components';
import Context from '../context';

const PaymentMethodForm = ({ onSubmit, token=false }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [localError, setError] = useState(false);
    const { state } = useContext(Context);
    const error = state.errors.payment_method;
    const success = state.success.payment_method;

    async function handleSubmit(e) {
        e.preventDefault();
        setError(false);
        setLoading(true);
        if (!stripe || !elements) return;
        const cardElement = elements.getElement(CardElement);

        let res;
        if (token) {
            res = await stripe.createToken(cardElement, { currency: 'usd' });
        } else {
            res = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
        };
        
        if (res.error) {
            setLoading(false);
            setError(res.error.message);
        } else {
            token ? await onSubmit(res.token.id) : await onSubmit(res.paymentMethod.id);
            setLoading(false);
        }
    };

    return(
        <form onSubmit={handleSubmit}>
            <div className="input-primary">
                <CardElement />
            </div>
            <ErrorBox error={localError || error} />
            <SuccessBox success={success} />
            <div className='flex justify-end'>
                <Submit
                    id='payment-method-submit'
                    copy="Save"
                    loading={loading}
                    style={{marginTop: '10px'}}
                />
            </div>
        </form>
    );
};

export default PaymentMethodForm;