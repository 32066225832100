import React from 'react';
import { withStuff } from "../hocs";
import { ClientDashboard, AdvisorDashboard } from '../pages';

const Dashboard = ({ state }) => {
    
    if (state.user.type === 'AdvisorUser' || state.user.type === 'HealthAdvisorUser') {
        return(
            <AdvisorDashboard />
        );
    } else if (state.user.type === 'ClientUser') {
        return <ClientDashboard />;
    };

    
};

export default withStuff(Dashboard, {
    api: true,
    state: true,
    loader: 'user',
});
