import React from 'react';
import { withStuff } from "../hocs";
import { Calendar, Breadcrumb } from '../components';

const Schedule = ({ state }) => {

    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0'>
            <Breadcrumb self="Schedule"/>
            <div className='mt-12'>
            <Calendar
                advisorView={!!state.client}
                user={state.client || state.user}
            />
            </div>
        </div>
    );
};

export default withStuff(Schedule, {
    state: true,
    api: true,
    loader: ["user", "client"],
    effect: ({ api, match }) => {
        if (match.params.id) {
            api.getClient(match.params.id);
        } else {
            api.stopLoading("client");
            api.getUser();
        }
    }
});
