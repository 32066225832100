import React, { useState, useEffect } from 'react';

const RadioInput = ({ _ref_, onChange, multi=false, color="#F16028", textSize="text-lg", ...input }) => {
	const [value, setValue] = useState(input.defaultValue || '');

	useEffect(() => {
		onChange && onChange({ target: { name: input.name, value }});
	}, [value]);


	function handleClick(v) {
        setValue(v);
	};

	function parseOptions(options, render) {
		if (Array.isArray(options)) {
			return options.map((value, i) => {
				return render(value, value, i);
			});
		} else {
			return Object.keys(options).map((key, i) => {
				return render(key, options[key], i);
			});
		
		}
	};

	return(
		<div>
			<input name={input.name} type="hidden" ref={_ref_} value={value} />
			{parseOptions(input.options, (v, n, i) =>
				<div
					className="flex text-md capitalize my-2 p-2  bg-move-white border-solid border-2 border-move-lslate radio-option items-center rounded-md cursor-pointer"
					onClick={() => handleClick(v)}
					key={i}
				>
					{
						value == v

						? 	<div className='dot-circle' style={{ borderColor: color }}>
                                <div style={{ background: color }} />
                            </div>

						: 	<span className="p-2 bg-move-white border-2 border-solid border-move-lslate shadow-md rounded-full" />
					}
					<label
						style={{
							color: value == v ? color : '',
                            marginLeft: '10px',
						}}
						className={`cursor-pointer`}
					>
						{n}
					</label>
				</div>
			)}
		</div>
	);
};

export default RadioInput;
