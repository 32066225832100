import React from 'react';
import { withStuff } from "../hocs";
import Logo from '../assets/logo.svg';
import { QuestionFlow, PaymentPlan } from '../components';
import { accountParams, userOnboardingParams } from '../params';

const Onboarding = ({ state, api }) => {
    function handleChange(step) {
        api.updateOnboarding({
            onboarding_step: step
        });
    };

    let steps = [
        {
            header: 'Tell us about your firm',
            inputs: accountParams(state.account),
            onSubmit: async (params) => {
                return await api.updateAccount(params);
            },
            type: 'update_account',
        },
        {
            header: 'Tell Us About Yourself',
            inputs: userOnboardingParams(state.user),
            onSubmit: async (params) => {
                return await api.updateUser(state.user.id, params);
            },
            type: 'update_user',
        },
        {
            header: 'Payment Details',
            onSubmit: async () => {
                const paymentMethodSubmit = document.getElementById('payment-method-submit');
                if (paymentMethodSubmit) {
                    paymentMethodSubmit.click();
                    return false;
                } else {
                    return true;
                }
            },
            child: () => {
                return(
                    <>
                        <div className='mb-4' >
                            You will not be charged during the free trial period. You can cancel before the trial period ends to prevent getting charged. Move Health includes a 30 day free trial period. Subscriptions can be annual or monthly and start the day the 30 day free trial period ends.
                        </div>
                        <PaymentPlan
                            onboarding={true}
                            afterUpdate={() => {
                                document.getElementById('question-flow-submit').click();
                            }}
                        />
                    </>
                );
            },
            type: 'update_account',
        },
        {
            header: 'Tell Us About Yourslf',
            inputs: [
                {
                    type: 'text',
                    label: 'Spouse Name (if applicable)',
                    name: 'spouse_name',
                    defaultValue: state.user.spouse_name,
                },
                {
                    label: 'What type of insurance are you looking for?',
                    type: 'select',
                    name: 'client_request_type',
                    required: true,
                    options: {
                        pre_65: 'Pre-65 Health Insurance',
                        medicare: 'Medicare',
                        combo: 'Pre-65 and Medicare',
                    },
                    defaultValue: state.user.client_request_type,
                },
                {
                    label: 'How can we help?',
                    type: 'textarea',
                    name: 'client_notes',
                    rows: 5,
                    defaultValue: state.user.client_notes,
                }
            ],
            onSubmit: async (params) => {
                return await api.updateUser(state.user.id, params);
            },
            type: 'update_user',
            userType: 'ClientUser'
        },
        
    ];

    steps = steps.filter((step) => {
        if (step.type === 'update_account') {
            return state.user.update_account_in_onboarding;
        } else {
            if (step.userType) {
                return step.userType === state.user.type;
            } else {
                return true;
            }
        }
    });

    async function onComplete() {
        await api.updateOnboarding({
            onboarding_complete: true
        });
        setTimeout(() => {
            window.location.href = '/';
        }, 500);
    }


    return(
        <div className="font-move auth-container" >
            <img 
                className="auth-logo mb-4 mx-auto"
                src={Logo} 
                alt="Move Logo" 
            />
            <QuestionFlow
                defaultStep={state.user.onboarding_step}
                steps={steps}
                onStepChange={handleChange}
                onComplete={onComplete}
            />
        </div>
    );
};

export default withStuff(Onboarding, {
    state: true,
    api: true,
    dependency: "user",
    effect: ({ api, state }) => {
        if (state.user.admin)
            api.getAccount()
    }
});
