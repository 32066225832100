import React, { useState, useRef, useEffect } from 'react';
import { DirectUpload } from "@rails/activestorage";
import { ImageEditor } from "../components";

class UploaderService {
	constructor(file, url='/rails/active_storage/direct_uploads') {
		this.file = file;
    	this.upload = new DirectUpload(this.file, url, this)
  	}

  	create({ onComplete, onError, onProgress }) {
  		this.onProgress = onProgress;
  		this.upload.create(async (error, blob) => {
  			if (error && onError) {
  				onError(error);
  			} else {
  				onComplete(blob.signed_id);
  			}
  		})
  	}

  	directUploadWillStoreFileWithXHR(xhr) {
  	  	this.onProgress("Preparing upload...");

	  	xhr.upload.addEventListener("progress", (e) => {

	  	    const progress = (e.loaded / e.total) * 100;

	  	    if (progress) {
	  	    	this.onProgress(`${(progress ==  100 ? 99 : progress).toFixed(0)}%`);
	  	    }
	  	});
  	}

};

const UploadWrapper = ({ className, name, defaultValue, onDelete, onChange, onLoading, children, onEffect, editable=false }) => {	
	const hasDefaultValue = !!(defaultValue && defaultValue.name && defaultValue.url);	
	const [editImage, setEditImage] = useState(defaultValue);
  	const [showEdit, setShowEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState();
	const [completed, setCompleted] = useState(hasDefaultValue);
	const [blobId, setBlobId] = useState(defaultValue && defaultValue.signed_id);
	const [thumbnail, setThumnail] = useState(isImage(defaultValue) && defaultValue.url);
	const [videoThumbnail, setVideoThumbnail] = useState(isVideo(defaultValue));
	const [genericThumbnail, setGenericThumbnail] = useState(hasDefaultValue &&  !isImage(defaultValue) && !isVideo(defaultValue));
	const [fileName, setFileName] = useState(hasDefaultValue && defaultValue.name);
	const [url, setUrl] = useState(hasDefaultValue && defaultValue.url);
	const onDroped = defaultValue && defaultValue.file;
	const input = useRef();
	const uploaded = !!fileName;

	function handleDelete() {
		if (hasDefaultValue && !defaultValue.nonDeletable) api.deleteAttachment(defaultValue.id);
		onDelete && onDelete();
		setThumnail(false);
		setVideoThumbnail(false);
		setGenericThumbnail(false);
		setFileName();
		setBlobId();
		setCompleted(false);
		setProgress();
	};

	function handleDrop(){
		defaultValue && defaultValue.file && onFile(defaultValue.file);
	}

	useEffect(() => {
		onEffect && onEffect(handleDelete);
	}, []);
	
	useEffect(() => {
		handleDrop();
	}, [onDroped]);

	function isVideo(file={}) {
		return !!(file && file.type) && file.type.includes('video');
	};

	function isImage(file={}) {
		return !!(file && file.type) && file.type.includes('image');
	}

	function addThumbnail(file) {
		setFileName(file.name);
		if (isImage(file)) {
			const reader = new FileReader();
			reader.onload = e => {
				setThumnail(e.target.result)
			};
			reader.readAsDataURL(file);
			setVideoThumbnail(false);
		} else if (isVideo(file)) {
			setThumnail(false);
			setVideoThumbnail(true);
			setGenericThumbnail(false);
		} else {
			setThumnail(false);
			setVideoThumbnail(false);
			setGenericThumbnail(true);
		}
		
	};

	function isLoading() {
		setLoading(true);
		onLoading && onLoading(true);		
		setCompleted(false);
	}

	function isComplete() {
		setLoading(false);
		onLoading && onLoading(false);
		setCompleted(true);
	}

	function onFileSelected(e) {
	  	e.preventDefault();

	  	if (e.target.files && e.target.files.length) {
	  		directUpload(e.target.files);
	  	};
	};


	function onFile(file) {
		directUpload([file]);
	};

	function onDrop(e) {
	  	e.preventDefault();
	  	directUpload(e.dataTransfer.files);
	};

	function directUpload(files, edited=false) {
		const file = files[0];
		if (editable && !edited) {
			setEditImage(file);
			setShowEdit(true);
			return;
		};

		const uploader = new UploaderService(file);

		isLoading();

		function onError(error) {
			console.log(error)
		};

		uploader.create({
			onError,
			onComplete: (blobId, url) => {
				setBlobId(blobId);
				setUrl(url);
			  	isComplete();
			  	addThumbnail(file);
			  	onChange && onChange({ target: { name, value: blobId }});
			},
			onProgress: (progress) => {
				setProgress(progress);
			}
		});
	};

	return(
		<div className={className}>
			{editImage && showEdit && (
				<ImageEditor
					name={name}
					image={editImage}
					show={showEdit}
					onUpdate={file => {
						directUpload([file], true);
						setShowEdit(false);
					}}
					onClose={() => setShowEdit(false)}
				/>
			)}
			{children({
				loading, progress, completed, uploaded,
				thumbnail, videoThumbnail, genericThumbnail,
				fileName, onFileSelected, onDrop, blobId, handleDelete,
				onFile, url,
			})}
		</div>
	);
};

UploadWrapper.Service = UploaderService;

export default UploadWrapper;
