import React from 'react';
import { Table, Breadcrumb, CopyButton } from '../components';
import { withStuff } from "../hocs";

const ClientIndex = ({ state, header="All Estimates", limit=5, search=false }) => {
    const estimations = state.estimations || [];
    
    const schema = {
        name: {
            children: ({datum}) => {
                return(
                    <a
                        className='no-underline'
                        href={`/estimations/${datum.id}`}
                    >
                        <div className='text-move-green text-md font-semibold'>{datum.name}</div>
                    </a>
                );
            }
        },
        type: {
            label: 'Type',
        },
        dependents_count: {
            label: 'Applicants',
        },
        updated_at: {
            label: 'Last Updated',
            children: ({datum}) => {
                const date = new Date(datum.updated_at);
                return(
                    <div>{date.toLocaleString("en-US", {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                    })}</div>
                );
            }
        },
        created_at: {
            label: 'Created',
            children: ({datum}) => {
                const date = new Date(datum.created_at);
                return(
                    <div>{date.toLocaleString("en-US", {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                    })}</div>
                );
            }
        },
        actions: {
            label: 'Actions',
            children: ({datum}) => {
                return(
                    <div className='flex justify-between items-center'>
                        <div className='space-x-4'>
                            <a
                                onClick={() => {
                                    api.duplicateEstimation(datum.id);
                                }}
                            >
                                <button className='btn-icon'>
                                    <i class="fa-regular fa-clone"></i> Duplicate
                                </button>
                            </a>
                            <CopyButton
                                copyText={datum.share_url}
                                preText="Copy Share Link"
                                postText="Link Copied!"
                            />
                        </div>
                        <a
                            className='text-red-500 hover:text-red-700 cursor-pointer ml-4'
                            onClick={() => {
                                if (window.confirm('Are you sure you want to delete this estimation?'))
                                    api.destroyEstimation(datum.id)
                            }}
                        >
                            <span className='text-xs'><i className="fa-solid fa-x" /></span>
                        </a>
                    </div>
                );
            }
        }
    }

    return(
        <div className='font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0 ronu'>
            <Breadcrumb self="Estimator"/>
            <div className='mt-12 w-full rounded-md shadow-sm bg-move-lgrey p-3'>
                <div className='flex justify-between items-start'>
                    <span className='text-lg font-medium'>{header}</span>
                    
                    <a className="rounded-md bg-move-orange text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2" href="/estimations/new">
                        <button type="button">
                            <i class="fa-solid fa-circle-plus mr-2"></i>New Estimate
                        </button>
                    </a>        
                </div>
                {
                    state.estimations?.length === 0

                    ?   <div className='text-center my-5'>
                            <p>No estimations yet.</p>
                        </div>
                    
                    :   <Table
                            schema={schema}
                            data={state.estimations || []}
                        />
                }
            </div>          
        </div>
    )
};

export default  withStuff(ClientIndex, {
    state: true,
    api: true,
    loader: 'estimations',
    effect: async ({ api }) => {
        api.getEstimations();
    }
});