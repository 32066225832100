import React from 'react';
import { Breadcrumb } from '../components';

const EmbedEstimator = () => {
    return(
        <div className="p-10 flex-col">
            <div className="sticky top-0 py-2" style={{background: 'white'}}>
                <Breadcrumb parent="Estimate" parentHref="/estimations/new" self="KFF"/>
            </div>
            <div className='flex-grow h-full'>
                <iframe frameborder="0" src="https://www.kff.org/interactive/subsidy-calculator-frameless" width="100%" height="4000"></iframe>
            </div>
        </div>
    )

}

export default EmbedEstimator;
