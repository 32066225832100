import React, { useContext, useEffect } from 'react';
import * as Pages from "./pages";
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute, NavBar, ErrorBox, UnconfirmedEmail } from './components';
import Context from './context';
import { withStuff } from "./hocs";
const { Onboarding } = Pages;

const routes = [
  {
    path: "/users",
    page: "Users",
    private: true,
  },
  {
    path: "/settings",
    page: "Settings",
    private: true,
  },
  {
    path: "/",
    page: "Dashboard",
    private: true,
  },
  {
    path: "/clients/new",
    page: "NewClient",
    private: true,
  },
  {
    path: '/confirm_email/:token',
    page: 'ConfirmEmail',
    nav: false,
  },
  {
    path: '/reset_password/:token',
    page: 'ResetPassword',
    nav: false,
  },
  {
    path: '/login',
    page: 'Login',
    nav: false,
  },
  {
    path: '/reset_password',
    page: 'RequestResetPassword',
    nav: false,
  },
  {
    path: '/signup',
    page: 'SignUp',
    nav: false,
  },
  {
    path: '/invite/:token',
    page: 'SignUp',
    nav: false,
  },
  {
    path: '/schedule',
    page: 'Schedule',
    private: true,
  },
  {
    path: '/schedule/:id',
    page: 'Schedule',
    private: true,
  },
  {
    path: '/subscribe',
    page: 'Subscribe',
    advisorOnly: true,
    private: true,
  },
  {
    path: '/profile',
    page: 'Profile',
    private: true,
  },
  {
    path: '/profile/:id',
    page: 'Profile',
    private: true,
  },
  {
    path: '/users',
    page: 'Users',
    private: true,
  },
  {
    path: '/accounts/:id',
    page: 'Account',
    private: true,
  },
  {
    path: '/account',
    page: 'Account',
    private: true,
  },
  {
    path: '/accounts',
    page: 'Accounts',
    private: true,
  },
  {
    path: '/advisors/new',
    page: 'NewAdvisor',
    private: true,
  },
  {
    path: '/survey',
    page: 'Survey',
    private: true,
  },
  {
    path: '/clients',
    page: 'Clients',
    private: true,
  },
  {
    path: '/clients/:id',
    page: 'ClientShow',
    private: true,
  },
  {
    path: '/policies',
    page: 'Policies',
    private: true,
  },
  {
    path: '/clients/:id/policies',
    page: 'Policies',
    private: true,
  },
  {
    path: '/policy_import',
    page: 'PolicyImport',
    private: true,
  },
  {
    path: '/estimations/new',
    page: 'EstimationForm',
    private: true,
  },
  {
    path: '/estimations/:id/edit',
    page: 'EstimationForm',
    private: true,
  },
  {
    path: '/estimations/:id',
    page: 'EstimationForm',
    private: true,
  },
  {
    path: '/estimations',
    page: 'Estimations',
    private: true,
  },
  {
    path: '/estimations/:id/view',
    page: 'PublicEstimation',
    nav: false,
  },
  {
    path: '/kff-estimator',
    page: 'EmbedEstimator',
  },
  {
    path: '/root/estimator',
    page: 'RootEstimator',
    nav: false,
  },
  {
    path: '/root/estimator/:id',
    page: 'RootEstimation',
    nav: false,
  },
  {
    path: '/root/kff-estimator',
    page: 'RootEmbedEstimator',
    nav: false,
  }
];

const buildComponent = ({
  path,
  page,
  nav=true,
  ...config
}) => {

  const Page = withStuff(Pages[page], config);

  return (props) => {
    const { state, api } = useContext(Context);
    useEffect(() => {
      if (config.private) {
        api.getUser();
      }
    }, []);

    const isAdvisor = state.user && (state.user.type === 'AdvisorUser' || state.user.type === 'HealthAdvisorUser');
    let Comp;

    if (!config.private || !state.user) {
      Comp = Page;
    } else if (!state.user.onboarding_complete) {
      Comp = Onboarding;
    } else if (!state.user.email_confirmed) {
      Comp = UnconfirmedEmail;
    } else {
      Comp = Page;
    }

    let showNav = nav && state.user?.onboarding_complete;

    return (
      <>
        {showNav && <Route exact component={NavBar} />}
        <ErrorBox error={state.errors.standard} />
        <div className={`${showNav && isAdvisor ? 'lg-ml-240' : 'ml-auto'} mr-auto`} style={{ maxWidth: (!showNav || isAdvisor) ? '' : '1024px'}}>
          <Comp {...props} />
        </div>
      </>
    );
  };
};

const buildRoutes = (routes) => {
  routes = routes.map((route) => {
    route.Component = buildComponent(route);
    return route;
  });


  const _404_ = buildComponent({
    page: "_404_",
    stripeBanner: false,
  });

  return () => {

    return (
      <Switch>
        {routes.map((route, i) =>
          route.private ? (
            <PrivateRoute
              key={i}
              path={route.path}
              exact
              component={route.Component}
            />
          ) : (
            <Route
              key={i}
              path={route.path}
              exact
              component={route.Component}
            />
          )
        )}
        <Route component={_404_} />
      </Switch>
    );
  };
};

export default buildRoutes(routes);