import React, { useContext, } from 'react';
import Context from '../context';
import Logo from '../assets/logo.svg';
import { Form } from '../components';
import { clientId, useGsi, callback } from '../hooks/use_gsi';
window.callback = callback;

const Login = ({ location }) => {
    const { api, state } = useContext(Context);
    useGsi();

    const loading = state.loading.login;
    const error = state.errors.login;

    return(
        <div class="font-move place-content-center h-screen w-screen">
        <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <img class="mx-auto h-8 mb-6 w-auto" src={Logo} alt="Move Health Partners"/>
                 <h2 class="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-move-dslate">Welcome. Sign In.</h2>
            </div>

            <div class="mb-3 sm:mx-auto sm:w-full sm:max-w-sm">
                <Form
                        className="space-y-6"
                        onSubmit={api.login}
                        submitCopy="Sign in to account"
                        type="login"
                        col="12"
                        inputs={[
                            {
                                label: 'Email',
                                type: 'email',
                                key: 'email',
                                placeholder: 'Email'
                            },
                            {
                                label: 'Password',
                                type: 'password',
                                key: 'password',
                                placeholder: 'Password'
                            }
                        ]}
                    >
                </Form>
                <a href='/reset_password' className="mt-2 text-sm text-move-dslate hover:text-move-slate-600 text-center block no-underline">Forgot password?</a>
                <p class="mt-12 text-center text-sm text-move-slate">
                Don't have an account yet? <a href="/signup" class="font-semibold leading-6 text-move-orange hover:text-move-orange-600 no-underline">Get Access</a>
                </p>
            </div>
        </div>
        </div> 
    )
}

export default Login;